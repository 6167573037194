<header [ngClass]="{'auth-header': UserAuthenticated && userInfo }" class="main-header sticky-top">
  <nav [@loadedFadeAnimation]="'visible'" class="navbar navbar-light navbar-expand-lg top-navbar bg-white shadow-sm">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/home']">
        @if(userInfo && userInfo.RolesListString.includes("MOTRIO")){
        <img src="/assets/images/logotipo-rg-punto-a-punto-motrio.svg" alt="Punto a punto" class="logo-header">
        } @else {
        <img src="/assets/images/logotipo-rg-punto-a-punto.svg" alt="Punto a punto" class="logo-header">
        }
      </a>
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenuContainer" aria-controls="navbarMenuContainer" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div [ngClass]="{'justify-content-end': !menu }" class="collapse navbar-collapse" id="navbarMenuContainer">
        <ul *ngIf="menu" app-menu [Items]="menu" [@loadedFadeAnimation]="menu.length ? 'visible' : 'void'" class="navbar-nav components me-lg-auto" id="MainMenu">
        </ul>
        <!--Header Recursive Buttons-->
        <div [@loadedFadeAnimation]="'visible'" class="nav-right">
          <div *ngIf="!UserAuthenticated" class="header-user-signin">
            <button [@loadedFadeAnimation]="'visible'" [routerLink]="['/login']" class="btn-signin">
              <div class="svg-icon"><svg viewBox="0 0 150 150">
                  <use xlink:href="assets/images/icons/icon-login.svg#img" href="assets/images/icons/icon-login.svg#img"></use>
                </svg>
              </div> <span class="signin-text">Identifícate</span>
            </button>
          </div>
          <div *ngIf="UserAuthenticated && userInfo && userPoints" class="header-user-info">
            <a [@loadedFadeAnimation]="'visible'" [routerLink]="['/my-zone/my-info']" [title]="userInfo.User" class="header-user-name">
              <div class="header-user-icon">
                <svg viewBox="0 0 256 256">
                  <use xlink:href="assets/images/icons/icon-nav-user.svg#img" href="assets/images/icons/icon-nav-user.svg#img"></use>
                </svg>
              </div>
              <div class="welcome-text">Hola, <span class="welcome-text-name">{{userInfo.User}}</span></div>
            </a>
            <div class="header-user-tools">
              <div [@loadedFadeAnimation]="'visible'" class="header-user-status">
                <img src="assets/images/icons/box-open.png" />
                Tienes <span class="text-nowrap"><span class="header-user-points">{{userPoints.TotalPoints | number}}</span> puntos</span>
              </div>
              <div class="vr"></div>
              <div class="header-user-menu">
                <nav>
                  <ul class="nav">
                    <li class="nav-item dropdown" ngbDropdown>
                      <a [@loadedFadeAnimation]="'visible'" class="nav-link" tabindex="0" ngbDropdownToggle id="headerUserDropdown" role="button"> Mi área </a>
                      <ul ngbDropdownMenu aria-labelledby="headerUserDropdown" class="dropdown-menu dropdown-menu-end">
                        <div app-menu [Items]="menuMiArea"></div>
                        <li *ngIf="isAdminRenault" class="nav-item" class="admin-link nav-item" [ngClass]="{ 'active': isRouteActive('/renault-zone') }">
                          <a [routerLink]="['/renault-zone/user']" class="nav-link icon-link">
                            <div class="svg-icon">
                              <svg viewBox="0 0 150 150">
                                <use xlink:href="assets/images/icons/icon-nav-private-area.svg#img" href="assets/images/icons/icon-nav-private-area.svg#img"></use>
                              </svg>
                            </div>
                            <strong>Administrador Renault</strong>
                          </a>
                        </li>
                        <li *ngIf="isAdmin" class="nav-item" class="admin-link nav-item" [ngClass]="{ 'active': isRouteActive('/admin') }">
                          <a [routerLink]="['/admin/carrusel']" class="nav-link icon-link">
                            <div class="svg-icon">
                              <svg viewBox="0 0 150 150">
                                <use xlink:href="assets/images/icons/icon-nav-private-area.svg#img" href="assets/images/icons/icon-nav-private-area.svg#img"></use>
                              </svg>
                            </div>
                            <strong>Administrador</strong>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a ngbDropdownItem (click)="logout()" class="nav-link icon-link pointer">
                            <div class="svg-icon">
                              <svg viewBox="0 0 150 150">
                                <use xlink:href="assets/images/icons/icon-logout.svg#img" href="assets/images/icons/icon-logout.svg#img"></use>
                              </svg>
                            </div>
                            <strong>Cerrar sesión</strong>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthJwtService } from './auth-jwt.service';
import { Helpers } from '../helpers/helpers';

@Injectable({
  providedIn: 'root'
})
export class AuthJwtInterceptorService {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router,
    private authJwtService: AuthJwtService,
    private helpers: Helpers) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
    request = this.addAuthenticationToken(request);

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        
        if (request.url.includes("Login")) {          
          this.goToLogin();
          return throwError(err);     
        } 
            
        if (err.status === 401) {
            if (this.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap((res) => {
                  return next.handle(this.addAuthenticationToken(request))
                }));
            }

            else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);
              var refreshToken = this.authJwtService.getRefreshToken();
              var token = this.authJwtService.getToken();
              if(token.length == 0 || refreshToken.length == 0)
              {
                this.goToLogin();
                return throwError(err); 
              }

              let body = { "Token": token, "RefreshToken": refreshToken };

              return this.authJwtService.refreshToken(body).pipe(

                switchMap((resp: any) => {
                  if (resp) {
                    this.refreshTokenSubject.next(resp.token);

                    let newReq = this.addAuthenticationToken(request);
                    return next.handle(newReq);
                  }

                  this.goToLogin();
                  return throwError(err);
                }),
                catchError(_err => {
                  this.goToLogin();
                  return throwError(err);
                }),
                finalize(() => {
                  this.refreshTokenInProgress = false;
                })
              );
            }      
        }
        else {
          return throwError(err);
        }
      })
    );
  }

  addAuthenticationToken(request) {
    const accessToken = this.authJwtService.getToken();

    if (!!accessToken && (this.helpers.isSameOriginUrl(request) || this.helpers.isApiUrl(request))) {      
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });      
    }

    return request;
  }

  goToLogin(){
    this.authJwtService.logout();
    this.router.navigateByUrl('/login');
  }

}

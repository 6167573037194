import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { PatternsComponent } from './components/develop/patterns/patterns.component';
import { PointsComponent } from './components/points/points.component';
import { myzoneroutes } from './components/myzone/myzone-routing.module';
import { ConditionsAcceptComponent } from './components/conditions-accept/conditions-accept.component';
import { CookiesPolicyComponent } from './components/cookies-policy/cookies-policy.component';
import { LegalTextComponent } from './components/legal-text/legal-text.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'conditions-accept',
    component: ConditionsAcceptComponent
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      { path: 'home', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule), data: { animation: 'home' } },
      { path: 'login', loadChildren: () => import('./components/login/login.module').then(m => m.LoginModule), data: { animation: 'login' } },
      { path: 'como-conseguir-puntos', component: PointsComponent, data: { animation: 'points' } },
      { path: 'como-conseguir-puntos/:faqs', component: PointsComponent, data: { animation: 'pointsfaqs' } },
      { path: 'products', loadChildren: () => import('./components/products/products.module').then(m => m.ProductsModule), data: { animation: 'products' } },
      { path: 'promotions', loadChildren: () => import('./components/promotions/promotions.module').then(m => m.PromotionsModule), data: { animation: 'promotions' } },
      { path: 'ruta', loadChildren: () => import('./components/ruta/ruta.module').then(m => m.RutaModule), data: { animation: 'ruta' } },
      { path: 'news', loadChildren: () => import('./components/news/news.module').then(m => m.NewsModule), data: { animation: 'news' } },
      { path: 'services', loadChildren: () => import('./components/services/services.module').then(m => m.ServicesModule), data: { animation: 'services' } },
      { path: 'catalog', loadChildren: () => import('./components/catalog/catalog.module').then(m => m.CatalogModule), data: { animation: 'catalog' } },
      { path: 'politica-cookies', component: CookiesPolicyComponent, data: { animation: 'cookies' } },
      { path: 'informacion-legal', component: LegalTextComponent, data: { animation: 'legal-text' } },
      { path: 'develop/patterns', component: PatternsComponent, data: { animation: 'patterns' } },
      { path: 'my-zone', children: myzoneroutes },
      { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule), data: { animation: 'home' } },
      { path: 'renault-zone', loadChildren: () => import('./components/renaultzone/renaultzone.module').then(m => m.RenaultZoneModule), data: { animation: 'rzone' } },
    ]
  },
  { path: 'crm', loadChildren: () => import('./components/crm/actions-crm.module').then(m => m.ActionsCrmModule), data: { animation: 'actions' } },
  { path: '**', redirectTo: '/home' }
]

   @NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0] // [x, y]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], field : string, value : string): any[] {

        if (!items) return [];
        if (!field || !value) return items;
        return items.filter(it => it[field] === value);
        
    }
}

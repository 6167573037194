import { trigger, animate, style, transition, state } from '@angular/animations';

var testMode: boolean = false;
var animationTime: number = 1000;

if(testMode) {
  animationTime *= 10;
}

// Routable animations
export const fadeInOutAnimation =
  trigger('routeAnimation', [
    transition('* <=> *', [
      style({ opacity: 0 }),
      animate(animationTime + 'ms ease-in-out', style({ opacity: 1 }))
    ]),
  ]);

export const visibleContentAnimation =
  trigger('loadedFadeAnimation', [
    state('visible', style({
      opacity: 1
    })),
    state('hidden', style({
      opacity: 0
    })),
    state('void', style({
      opacity: 0
    })),
    transition('void <=> visible', [
      animate(animationTime + 'ms ease-in-out')
    ]),
  ]);

  export const heightContentAnimation =
  trigger('loadedHeightAnimation', [
    state('visible', style({
      opacity: 1,
      overflow: 'revert'
    })),
    state('hidden', style({
      opacity: 0,
      height: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      overflow: 'hidden'
    })),
    state('void', style({
      opacity: 0,
      height: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginTop: 0,
      marginBottom: 0,
      overflow: 'hidden'
    })),
    transition('void <=> visible', [
      animate((animationTime * .75) + 'ms ease-in-out')
    ])
  ]);

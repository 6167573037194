import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from "angular-datatables";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Select2Module } from 'ng-select2-component';
import { CatalogItemOrderTableComponent } from './catalog-item-order-table/catalog-item-order-table.component';
import { UserAnimationSarPointsComponent } from './user-animation-sar-points/user-animation-sar-points.component';
import { UserAnimationSarTimelineComponent } from './user-animation-sar-timeline/user-animation-sar-timeline.component';
import { DistributorCommonFilterComponent } from './distributor-common-filter/distributor-common-filter.component';
import { FamilySelectorComponent } from './family-selector/family-selector.component';
import { CrmInfoComponent } from './crm-info/crm-info.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { InfoDistributorComponent } from './info-distributor/info-distributor.component';
import { FilterPipe } from '../../pipes/filter.pipe';


@NgModule({
  declarations: [
    CatalogItemOrderTableComponent,
    UserAnimationSarPointsComponent,
    UserAnimationSarTimelineComponent,
    DistributorCommonFilterComponent,
    FamilySelectorComponent,
    CrmInfoComponent,
    InfoDistributorComponent,
    FilterPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    Select2Module,
    HighchartsChartModule
  ],
  exports: [
    CatalogItemOrderTableComponent,
    UserAnimationSarPointsComponent,
    UserAnimationSarTimelineComponent,
    DistributorCommonFilterComponent,
    FamilySelectorComponent,
    CrmInfoComponent,
    InfoDistributorComponent,
    FilterPipe
  ],
  providers: [
    DecimalPipe
  ]
})
export class SharedModule { }

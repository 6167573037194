import { PuntoapuntoFileDTO } from "./puntoapunto-file-dto";

export class PuntoapuntoProductDTO {
    IdProduct: string | null;
    Status: boolean;
    PublishDate: string;
    UnpublishDate: string | null;
    CreationDate: string;
    Title: string;
    HtmlFile:PuntoapuntoFileDTO;
    ImageFile:PuntoapuntoFileDTO;
    IsPublic:boolean;
    IsPrivate:boolean;
    ShowHome: boolean;
    PositiveFilters: string[];
    NegativeFilters: string[];
}

export class PuntoapuntoProductPostDTO {
    Status: boolean;
    PublishDate: string | null;
    UnpublishDate: string | null;
    Title: string;
    HtmlFile:File;
    ImageFile:File;
    IsPublic:boolean;
    IsPrivate:boolean;
    ShowHome: boolean;
    PositiveFilters: string[] | null;
    NegativeFilters: string[] | null;
}

export class ProductFilter {
    Take: number;
    Skip: number;
}

export class PuntoapuntoProductLogDTO {
    IdProduct: string;
}
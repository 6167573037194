import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { heightContentAnimation, visibleContentAnimation } from '../../../animations';
import { FamilyService } from '../../../services/family.service';
import { FamilyGroupBySegmentModelDTO, FamilyGroupSelectionDTO, FamilySelectionDTO, PuntoapuntoFamilyDTO } from '../../../models/puntoapunto-family-dto';
import { SharedService } from '../../../services/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-family-selector',
  templateUrl: './family-selector.component.html',
  animations: [visibleContentAnimation, heightContentAnimation]
})

export class FamilySelectorComponent implements OnInit {

  @Input() ShowPoints: boolean = false;
  @Input() selectedFamilies: FamilyGroupSelectionDTO[];
  model: FamilyGroupBySegmentModelDTO[];
  modelSearch: FamilyGroupBySegmentModelDTO[];
  rolData: any[] = [];
  familyFind: string = '';

  @Output() onFamilySelected = new EventEmitter<FamilyGroupSelectionDTO[]>();

  constructor(private service: FamilyService,
    private sharedService:SharedService) { }

  ngOnInit(): void {
    this.loadRoles();
    this.service.getFamilyGroupBySegment().subscribe(res => {
      this.model = res;
      this.modelSearch = res;
    });
  }

  loadRoles() {
    const that = this;
    this.sharedService.getPuntoapuntoRolForFamilyAnimation().subscribe(resp=>{
      this.rolData = resp;
    }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Error al recuperar los datos',
        text: 'Ocurrió un error'
      });
    });
  }

  updateRolData(event, family: FamilySelectionDTO){
    if (event.target.checked) {
      if (family.Roles.indexOf(parseInt(event.target.value)) < 0) {
        family.Roles.push(parseInt(event.target.value));
      }
    } else {
      if (family.Roles.indexOf(parseInt(event.target.value)) > -1)
      {
        family.Roles.splice(family.Roles.indexOf(parseInt(event.target.value)), 1);
      }
    }
    this.emitSelection();
  }

  addFamily(family: PuntoapuntoFamilyDTO, segment: string) {
    if(!this.selectedFamilies.some(x=>x.IdSegment == family.IdSegment)){
      let newSegment: FamilyGroupSelectionDTO = {
        IdSegment: family.IdSegment,
        Segment: segment,
        Families: []
      }
      this.selectedFamilies.push(newSegment);
    }

    let selectedSegment = this.selectedFamilies.find(x=>x.IdSegment == family.IdSegment);

    let newFamily: FamilySelectionDTO = {
      IdSegment: selectedSegment.IdSegment,
      IdFamily: family.IdFamilyInt,
      Family: family.Family,
      Description: family.Description,
      Reference: '',
      Roles: this.rolData.map(x=>x.value),
      Points: 1,
      Euros: 200,
    };

    let idx = selectedSegment.Families.findIndex(x=>x.IdFamily == family.IdFamilyInt);

    if(idx > -1) {
      Swal.fire({
        title: 'Esta familia ya se ha seleccionado. Quieres volver a añadirla?',
        text: "",
        icon: 'warning',
        buttonsStyling: false,
        customClass: {
          cancelButton: 'btn-dimgrey me-2',
          confirmButton: 'btn btn-outline-secondary'
        },
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: '<i class="fa fa-trash-o" aria-hidden="true"></i> Añadir',
        cancelButtonText: '<i class="bi bi-x-lg"></i> Cancelar',
        cancelButtonColor: '#d33',
        reverseButtons: true,
        focusCancel: true
      }).then((result) => {
        if (result.value) {

          selectedSegment.Families.push(newFamily);
          this.selectedFamilies.sort((a,b) => a.Segment.localeCompare(b.Segment));
          this.selectedFamilies.forEach(function(element){
            element.Families.sort((a,b) => a.Family.localeCompare(b.Family));
          });

        } else {
          return;
        }
      });
    } else {
      selectedSegment.Families.push(newFamily);

      this.selectedFamilies.sort((a,b) => a.Segment.localeCompare(b.Segment));
      this.selectedFamilies.forEach(function(element){
        element.Families.sort((a,b) => a.Family.localeCompare(b.Family));
      });
    }

    this.emitSelection();

  }

  removeFamily(family: FamilySelectionDTO){
    Swal.fire({
      title: '¿Quieres eliminar esta familia?',
      text: "",
      icon: 'warning',
      buttonsStyling: false,
      customClass: {
        cancelButton: 'btn-dimgrey me-2',
        confirmButton: 'btn btn-outline-secondary'
      },
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: '<i class="fa fa-trash-o" aria-hidden="true"></i> Eliminar',
      cancelButtonText: '<i class="bi bi-x-lg"></i> Cancelar',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      focusCancel: true
    }).then((result) => {
      if (result.value) {
        let selectedSegment = this.selectedFamilies.find(x=>x.IdSegment == family.IdSegment);
        let idx = selectedSegment.Families.findIndex(x=>x.IdFamily == family.IdFamily && x.Reference == family.Reference &&
                                                    x.Roles.every((element, index)=> element === family.Roles[index]));
        if(idx > -1){
          selectedSegment.Families.splice(idx, 1);
        }

        if(selectedSegment.Families.length == 0){
          let idxSegment = this.selectedFamilies.findIndex(x=>x.IdSegment == selectedSegment.IdSegment);
          if(idxSegment > -1){
            this.selectedFamilies.splice(idxSegment, 1);
          }
        }
        this.emitSelection();
      }
    });
  }

  emitSelection(){
    this.onFamilySelected.emit(this.selectedFamilies);
  }

  searchFamily() {
    const that=this;
    this.model = this.modelSearch.map(item => ({
        Segment: item.Segment,
        Familiy: item.Familiy.filter(family =>
            family.Family.toLowerCase().includes(that.familyFind.toLowerCase()) ||
            family.Description.toLowerCase().includes(that.familyFind.toLowerCase())
        )
    })).filter(item => item.Familiy.length > 0);
  }

}



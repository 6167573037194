import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ChangeOrderDTO } from '../models/change-order-dto';
import { DataTablesResponse } from '../models/datatables';
import { PuntoapuntoServiceDTO, PuntoapuntoServiceLogDTO, ServiceFilter } from '../models/puntoapunto-service-dto';


@Injectable({
  providedIn: 'root'
})

export class ServiceService {

  private pathAPI = environment.urlAPI;
  private controller = "PuntoapuntoService/";

  constructor(private http: HttpClient) { }


  public GetPuntoapuntoServiceLst(): Observable<PuntoapuntoServiceDTO[]> {
    return this.http.get<PuntoapuntoServiceDTO[]>(this.pathAPI + this.controller + "GetPuntoapuntoServiceLst/");
  }

  public GetPuntoapuntoAllServiceLst(filter: ServiceFilter): Observable<PuntoapuntoServiceDTO[]> {
    return this.http.post<PuntoapuntoServiceDTO[]>(this.pathAPI + this.controller + "GetPuntoapuntoAllServiceLst/", filter);
  }

  public get(id: string): Observable<PuntoapuntoServiceDTO> {
    return this.http.get<PuntoapuntoServiceDTO>(this.pathAPI + this.controller + id);
  }

  public post(data: FormData): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller, data);
  }

  public put(id: string, data: FormData): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + id, data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + 'ChangeStatus/' + id + '/' + idStatus, null);
  }

  public changePublic(id: string, Status: boolean): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + 'ChangePublic/' + id + '/' + Status, null);
  }

  public changePrivate(id: string, Status: boolean): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + 'ChangePrivate/' + id + '/' + Status, null);
  }

  public changeOrder(data: ChangeOrderDTO[]): any {
    return this.http.post<any>(this.pathAPI + this.controller + 'ChangeOrder', data);
  }

  public getDataTable(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + this.controller + 'GetDataTable/', data);
  }

  public getDataTableOrder(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + this.controller + 'GetDataTableOrder/', data);
  }

  public putPuntoapuntoServiceHome(data: PuntoapuntoServiceDTO[], i: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + 'PuntoapuntoService/putPuntoapuntoServiceHome/' + i, data);
  }

  public postLog(data: PuntoapuntoServiceLogDTO): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller + "InsertLog", data);
  }


}

<div class="nested-accordion-body">
  <h6 class="mb-0">Concesionario Principal</h6>
  <div class="statistics_campaign_data">
    <div class="col-auto border-start-3">
      <div class="lateral-bar"></div>DR: <strong>{{distributor.DrParent}}</strong>
    </div>
    <div class="col-auto">
      <div class="lateral-bar dimgrey"></div>Grupo PV: <strong>{{distributor.PvGroupParent}}</strong>
    </div>
    <div class="col-auto">
      <div class="lateral-bar primarylight"></div>Cuenta: <strong>{{distributor.BirParent}} - {{distributor.SocialNameParent}}</strong>
    </div>
  </div>
  <hr>
  <h6 class="mb-0">Datos del taller</h6>
  <div class="statistics_campaign_data">
    <div class="col-auto">Rol: <strong>{{distributor.Rol}}</strong></div>
    <div class="col-auto">Razón Social: <strong>{{distributor.SocialName}}</strong></div>
    <div class="col-auto">Id TRESOR: <strong>{{distributor.IdTresor}}</strong></div>
  </div>
  <div class="statistics_campaign_data">
    <div class="col-auto">Dirección: <strong>{{distributor.Address}}</strong></div>
    <div class="col-auto">CP: <strong>{{distributor.CP}}</strong></div>
    <div class="col-auto">Ciudad: <strong>{{distributor.City}}</strong></div>
    <div class="col-auto">Email: <strong>{{distributor.Email}}</strong></div>
    <div class="col-auto">Teléfono: <strong>{{distributor.Phone}}</strong></div>
  </div>
</div>
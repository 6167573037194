<div class="container pb-3">
  <h2 class="section-title">Política de cookies</h2>
</div>
<div class="container legal-text-container legal-text">
  <div class="row">
    <div class="col">
      <p class="lead-paragraph pt-4">Nuestras webs y aplicaciones utilizan tecnologías como las cookies para ofrecer una experiencia de calidad, fluida y personalizada.<br><br>
        La protección de tus datos online es una prioridad para Renault. Podrás modificar tu configuración en materia de cookies en cualquier momento gracias a nuestra herramienta de administración de cookies: <a data-trigger="cookies-config" class="plain-link pointer">ADMINISTRAR MIS PREFERENCIAS</a>.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <!-- <div ngbAccordion [closeOthers]="true" class="accordion-flush accordion-number px-0 px-md-3">
        <div *ngFor="let elem of faqs" ngbAccordionItem [collapsed]="elem.collapsed">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton [innerHTML]="elem.title"></button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div [innerHTML]="elem.content"></div>
              </ng-template>
            </div>
          </div>
        </div>
      </div> -->

      <div ngbAccordion class="accordion-flush accordion-number px-0 px-md-3">
        <div ngbAccordionItem>
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>¿PARA QUÉ SIRVEN LAS COOKIES QUE ESTA WEB UTILIZA?</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <p>Esta web utiliza diferentes tipos de cookies.</p>
                <h6>Cookies obligatorias</h6>
                <p>
                  El objetivo de estas cookies es permitir o facilitar la comunicación por medios electrónicos (detección de errores de conexión, identificación de puntos de conexión, seguridad de la página web, etc.) o prestar servicios de comunicación en línea bajo tu solicitud expresa (preferencias de visualización en pantalla, memorización de datos indicados en los formularios, panel de compra (en su caso), acceso a tu cuenta de usuario, etc.).
                </p>
                <p>
                  Estas cookies son necesarias para el buen funcionamiento de nuestra web.
                </p>
                <p>
                  No puedes oponerte al uso de este tipo de cookies y nuestra web no requerirá tu consentimiento cuando empiece a usarlas.
                </p>
                <h6>Cookies de rendimiento</h6>
                <p>
                  El objetivo de estas cookies es analizar el uso que se realiza de la web y la frecuencia, estudiar el recorrido del usuario o simplemente recopilar indicaciones globales relativas al flujo de visitas en la web (por ejemplo: selección de idioma, página anterior visitada, fecha y hora de la visita, preferencias en materia de cookies).
                </p>
                <p>
                  Estas cookies pueden tratar datos relativos a las páginas visitadas, a la duración de las visitan a dichas páginas, al navegador que utilizas para visitarlas, a tu participación un chat o chatbot en la web, etc.
                </p>
                <p>
                  Los datos recopilados por estas cookies también pueden utilizarse para crear algoritmos y perfiles a efectos de analizar tus intereses y preferencias potenciales con relación a nuestros productos y servicios. Podemos combinar estos datos con otros, como los procedentes de nuestra base de datos de clientes.
                </p>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem>
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>¿QUÉ COOKIES UTILIZA ESTA WEB?</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <p>Consulta la tabla siguiente:</p>
                <table class="cookies-table">
                  <tr>
                    <th>titular de la cookie</th>
                    <th>finalidad de la cookie</th>
                    <th>política de cookies</th>
                  </tr>
                  <tr>
                    <td>Google</td>
                    <td>Medición de audiencia</td>
                    <td><a href="https://policies.google.com/technologies/cookies?hl=es" target="_blank">política de cookies</a></td>
                  </tr>
                  <tr>
                    <td>Renault</td>
                    <td>Funcional, medición de audiencia, personalización de contenidos, promociones personalizadas
                    </td>
                    <td><a href="#">política de cookies</a></td>
                  </tr>
                </table>
                <p>En la medida de lo posible, estas cookies se alojan en servidores situados en el Espacio Económico Europeo (EEE). No obstante, como algunos de nuestros proveedores están situados en países de fuera del EEE, el tratamiento de tus datos personales puede realizarse en esos países, que pueden disponer de un reglamento en materia de datos personales diferente al de la Unión Europea. En esos casos, prestamos especial atención a que la transferencia de datos se realice de acuerdo con el reglamento aplicable y establecemos garantías para mantener un nivel de protección de tu privacidad y tus derechos fundamentales equivalente al que ofrece la Unión Europea (principalmente por medio del uso de las cláusulas contractuales tipo de la Comisión Europea). </p>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem>
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>¿CÓMO OBTENEMOS TU CONSENTIMIENTO?</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <p>Durante tu primera visita a nuestra web, te pedimos que prestes tu consentimiento, que te opongas o que administres tus preferencias relativas al uso de las cookies en una ventana con información específica.</p>
                <p>Puedes retirar tu consentimiento en cualquier momento o modificar tu configuración (por ejemplo, aceptando solo algunos tipos de cookies) en <a data-trigger="cookies-config" class="plain-link pointer">nuestra herramienta específica.</a></p>
                <p>Te informamos de que, conforme al reglamento, las cookies necesarias para el funcionamiento de la web no están sometidas a tu consentimiento y, por tanto, no podrás desactivarlas en nuestra herramienta de administración de preferencias. Llegado el caso, podrás oponerte a las mismas a través de la configuración de tu navegador (tal y como se indica en el apartado "Cómo oponerse a las cookies" que figura más adelante) pero tu experiencia en la web podrá verse afectada.</p>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem>
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>¿DURANTE CUÁNTO TIEMPO SE REGISTRA TU CONSENTIMIENTO?</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <p>Cuando aceptas o rechazas la totalidad o una parte de las cookies de nuestra web, tu decisión queda registrada durante un periodo máximo de 6 (seis) meses. Esto significa que, cuando accedas a nuestra web posteriormente, no volveremos a preguntarte sobre el uso de las cookies durante ese periodo de tiempo en la medida en que ya hayamos registrado tu decisión.</p>
                <p>Una vez transcurrido ese periodo de tiempo, volveremos a pedirte que prestes tu consentimiento, que te opongas o que administres tus preferencias relativas al uso de cookies.</p>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem>
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>¿CÓMO SE ADMINISTRAN LAS COOKIES?</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <h6>Cómo oponerse a las cookies</h6>
                <p>Si no deseas que se usen y/o guarden cookies en tu terminal a través de esta web, se instalará una cookie de exclusión en tu dispositivo para que podamos registrar la información según la cual has optado por rechazar la totalidad o una parte de las cookies. Si eliminas esta cookie de exclusión, no podremos identificarte como usuario que ha rechazado el uso de cookies (esto significa que tendrás que volver a expresar tu voluntad durante tu próxima visita a nuestra web).</p>
                <p>
                  Tienes a tu disposición otras opciones para administrar las cookies y principalmente oponerte a su uso y/o eliminarlas.
                </p>
                <h6>Herramienta de administración de preferencias</h6>
                <p>Dispones de una herramienta de administración de preferencias en materia de cookies que te permitirá administrar las cookies utilizadas y/o guardadas en esta web. Haciendo clic en el enlace que figura a continuación podrás acceder en cualquier momento al configurador y modificar tus preferencias para cada tipo de cookie.</p>
                <p><a data-trigger="cookies-config" class="plain-link pointer">ADMINISTRACIÓN DE COOKIES</a></p>
                <h6>Parámetros del navegador</h6>
                <p>Asimismo, tienes la posibilidad de administrar las cookies a través de tu navegador.</p>
                <p>Aunque la mayoría de los navegadores están configurados por defecto y aceptan la instalación de cookies, si lo deseas, tienes la posibilidad de aceptar todas las cookies, rechazarlas sistemáticamente o elegir cuáles aceptas en función de su emisor. También puedes eliminar regularmente las cookies de tu terminal a través de tu navegador.</p>
                <p>Eso sí, no olvides configurar todos los navegadores de tus diferentes terminales (tabletas, smartphones, ordenadores...).</p>
                <p>Para administrar las cookies y tus preferencias, la configuración de cada navegador es diferente. Está descrita en el menú de ayuda del navegador, que te permitirá saber de qué forma puedes modificar tus preferencias en materia de cookies. A modo de ejemplo:</p>
                <p>
                  - para Internet ExplorerTM: <a href="http://windows.microsoft.com/es-ES/windows-vista/Block-or-allow-cookies">http://windows.microsoft.com/es-ES/windows-vista/Block-or-allow-cookies</a>;<br>
                  - para SafariTM: <a href="https://support.apple.com/es-es/safari" target="_blank">https://support.apple.com/es-es/safari</a>;<br>
                  - para ChromeTM: <a href="https://support.google.com/accounts/answer/32050?hl=es" target="_blank">https://support.google.com/accounts/answer/32050?hl=es</a>;<br>
                  - para FirefoxTM: <a href="https://support.mozilla.org/es/kb/Borrar%20cookies" target="_blank">https://support.mozilla.org/es/kb/Borrar%20cookies</a><br>
                  - para OperaTM: <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">https://help.opera.com/en/latest/web-preferences/#cookies</a>.
                </p>
                <h6>Módulos de oposición de editores</h6>
                <p>Puedes optar por desactivar algunas cookies de terceros accediendo directamente a la página de su emisor. Así pues:</p>
                <p>
                  - Para desactivar las cookies de Google Analytics, accede a la página: <a href="https://tools.google.com/dlpage/gaoptout?hl=es" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=es</a>
                </p>

              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { Helpers } from '../../helpers/helpers';
import { ProductFilter, PuntoapuntoProductDTO } from '../../models/puntoapunto-product-dto';
import { ProductService } from '../../services/product.service';
import { ServiceService } from '../../services/service.service';
import { PuntoapuntoServiceDTO, ServiceFilter } from '../../models/puntoapunto-service-dto';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { fadeInOutAnimation } from '../../animations';

@Component({
  templateUrl: './default-layout.component.html',
  animations: [fadeInOutAnimation]
})

export class DefaultLayoutComponent implements OnInit {

  prefooterModulesSet: 'void' | 'home' | 'login' | 'inner' | 'help' = 'home';
  breadcrumbs: [{
    text?: string,
    link?: string
  }?] = [];
  backLink: [
    text?: string,
    link?: string
  ] = [];

  productFilter: ProductFilter = new ProductFilter();
  products: PuntoapuntoProductDTO[] = [];

  serviceFilter: ServiceFilter = new ProductFilter();
  services: PuntoapuntoServiceDTO[] = [];

  sublinks: {}[] = [];

  constructor(
    private contexts: ChildrenOutletContexts,
    public helper: Helpers,
    private productService: ProductService,
    private serviceService: ServiceService
  ) {
    this.productFilter.Skip = 0;
    this.productFilter.Take = 10;
    this.serviceFilter.Skip = 0;
    this.serviceFilter.Take = 10;
  }

  ngOnInit() {
    this.toggleScrollToTopButton();
    this.getSublinks();
  }

  getAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  };

  getPrefooterModulesSet(evt) {
    if (evt.prefooterModulesSet) {
      this.prefooterModulesSet = evt.prefooterModulesSet;
    } else {
      this.prefooterModulesSet = 'void';
    }
  }

  getBreadcrumbs(evt) {
    setTimeout(() => {
      if (evt.breadcrumbs) {
        this.breadcrumbs = evt.breadcrumbs;
        this.backLink = evt.backLink;
      } else {
        this.breadcrumbs = [];
        this.backLink = null;
      }
    }, 5);
  }

  getAllProducts() {
    this.productService.GetPuntoapuntoAllProductLst(this.productFilter).subscribe(resp => {
      this.products = resp;
      this.addLinkstoMainMenu('IdProduct', 'products', 'menuProductos', this.products);
    });
  }

  getAllServices() {
    this.serviceService.GetPuntoapuntoAllServiceLst(this.serviceFilter).subscribe(resp => {
      this.services = resp;
      this.addLinkstoMainMenu('IdService', 'services', 'menuServicios', this.services);
    });
  }

  addLinkstoMainMenu(idString, partialRoute, idMenu, obj) {
    const Items: any[] = [];

    for (const i in obj) {
      Items.push({
        'Id': obj[i][idString],
        'Title': obj[i]['Title'],
        'Url': partialRoute + '/detail/' + obj[i][idString],
        'Class': 'subLink',
        'Roles': [],
        'SubItems': []
      });
    };

    this.sublinks.push({ idMenu: idMenu, Items: Items });
  }

  getSublinks() {
    this.getAllProducts();
    this.getAllServices();
  }

  toggleScrollToTopButton() {
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 480) {
        $('#back-to-top').addClass('visible');
        if ($('#jsd-widget') && $('#jsd-widget').length) {
          $('#back-to-top').css({ marginBottom: '3.5rem' });
        }
      } else {
        $('#back-to-top').removeClass('visible');
      }
    });
  }

  scrollToTop(tooltip: NgbTooltip) {
    const scrollDuration: number = 400;
    $('body,html').animate({
      scrollTop: 0
    }, scrollDuration);

    setTimeout(() => {
      tooltip.close();
    }, scrollDuration);

    return false;
  }
}


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AuthJwtService } from '../../services/auth-jwt.service';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './conditions-accept.component.html'
})
export class ConditionsAcceptComponent implements OnInit {

  acceptCrm = false;

  constructor(
    private userService: UserService, private router: Router, private authJwtService: AuthJwtService) { }

  ngOnInit(): void {
  }


  setConditions() {

    if(!this.acceptCrm)
    {
      Swal.fire({
        title: 'No has aceptado recibir información acerca de nuestras promociones',
        text: "",
        icon: 'warning',
        buttonsStyling: false,
        customClass: {
          cancelButton: 'btn btn-info w-90 mb-2',
          confirmButton: 'btn btn-outline-secondary btn-sm w-90'
        },
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'NO ACEPTAR COMUNICACIONES',
        cancelButtonText: 'ACEPTAR COMUNICACIONES Y CONTINUAR',
        cancelButtonColor: '#d33',
        focusCancel: true,
        reverseButtons: true,
        backdrop: false
      }).then((result) => {
        if (!result.value) { // Acepta recibir comunicaciones
          this.acceptCrm = true;
        }
        setTimeout(() => {
          this.setConditionsCall();
        }, 300);
      })
    }
    else{
      this.setConditionsCall();
    }

  }

  setConditionsCall(){
    this.userService.SetConditionsDistributor(this.acceptCrm).subscribe(() => {
      var refreshToken = this.authJwtService.getRefreshToken();
      var token = this.authJwtService.getToken();
      let body = { "Token": token, "RefreshToken": refreshToken };
      this.authJwtService.refreshToken(body).subscribe(()=>{
        this.router.navigate(['/home']);
      });
    });
  }

  cancel() {
    this.authJwtService.logout();
    this.router.navigate(['/home']);
  }

}

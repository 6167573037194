import { Component, Input } from '@angular/core';
import { heightContentAnimation } from '../../../animations';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  animations: [heightContentAnimation]
})

export class BreadcrumbsComponent {

  @Input() breadcrumbs: [{
    text?: string,
    link?: string
  }?];

  @Input() backLink:  [
    text?: string,
    link?: string
  ] = null;

  constructor() {}
}

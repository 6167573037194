import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {  BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { UserAnimationPointDTO } from '../models/user-animation-point-dto';
import { DistributorParams } from '../models/distributor-params';

@Injectable({
  providedIn: 'root'
})

export class UserAnimationPointService {

    private pathAPI = environment.urlAPI;
    private controller = "UserAnimationPoint/";

    private userAnimationPoints = new BehaviorSubject<UserAnimationPointDTO>(null);

    constructor(private http: HttpClient) { }

    public ReloadUserAnimationPoints() {
        this.getLoggedUserAnimationPoints().subscribe(resp=>{            
            this.userAnimationPoints.next(resp);
        });
    }

    public getLoggedUserAnimationPoints(): Observable<UserAnimationPointDTO> {
        return this.http.get<UserAnimationPointDTO>(this.pathAPI + this.controller + 'GetLoggedUserAnimationPoints');
    }

    public getUserAnimationPointsById(idUser: string): Observable<UserAnimationPointDTO> {
        return this.http.get<UserAnimationPointDTO>(this.pathAPI + this.controller + 'GetUserAnimationPoints/' + idUser);
    }

    public getUserAnimationPointsByIdTresor(idTresor: number): Observable<UserAnimationPointDTO> {
        return this.http.get<UserAnimationPointDTO>(this.pathAPI + this.controller + 'GetUserAnimationPointsByTresor/' + idTresor);
    }

    public getUserAnimationPointsByDistributorParams(filters: DistributorParams): Observable<UserAnimationPointDTO> {
        return this.http.post<UserAnimationPointDTO>(this.pathAPI + this.controller + 'GetUserAnimationPointsByDistributorParams', filters);
    }

    public getUserAnimationPoints(): Observable<UserAnimationPointDTO> {

        //if (this.userAnimationPoints.value != null) {
            return this.userAnimationPoints.asObservable();
        // }
        // else {
        //     return this.getLoggedUserAnimationPoints().pipe(map((userPoints) => {
        //         this.userAnimationPoints.next(userPoints);
        //         return userPoints;
        //     }),
        //     catchError((err) => {
        //         return this.userAnimationPoints.asObservable();
        //     }));
        // }    
    }

    public setUserAnimationPoints(data: UserAnimationPointDTO) {    
        this.userAnimationPoints.next(data);
    }

    public getUserAnimationPointsExcel(filters: DistributorParams): Observable<Blob> {
        let requestOptions: Object = {
          responseType: 'blob'
        }
        let endpoint = this.pathAPI + this.controller + 'GetUserAnimationPointsExcel';
        return this.http.post<Blob>(endpoint, filters, requestOptions);
    }

    public getUserAnimationPointsByAnimationExcel(filters: DistributorParams, animationStartDate: Date, animationEndDate: Date): Observable<Blob> {
        let requestOptions: Object = {
            responseType: 'blob'
        }
        let endpoint = this.pathAPI + this.controller + 'GetUserAnimationPointsByAnimationExcel/' + animationStartDate + '/' + animationEndDate;
        return this.http.post<Blob>(endpoint, filters, requestOptions);
    }

    public getUserInitialPointsExcel(idInitialPointType: number, year: number): Observable<Blob> {
        let requestOptions: Object = {
            responseType: 'blob'
        }
        let endpoint = this.pathAPI + this.controller + 'GetUserInitialPointsExcel/' + idInitialPointType + '/' + year;
        return this.http.post<Blob>(endpoint, null, requestOptions);
    }

}
<!-- <div class="cont__last-order">
  <h5 class="subtitle">Ver detalles de pedidos</h5>
</div> -->

<div class="cont__filter-order">
  <div class="filter-order">
    <div class="data-filters">
      <div *ngIf="yearsData && yearsData.length>0" class="form-group">
        <label class="form-label">Año</label>
        <select2 [(ngModel)]="yearSelected" placeholder="Seleccionar año" [data]="yearsData" (update)="updateYear()"></select2>
      </div>

      <div class="form-group" *ngIf="statusData && statusData.length>0">
        <label class="form-label">Estado</label>
        <select2 [(ngModel)]="statusSelected" placeholder="Estado del pedido" [data]="statusData" (update)="updateStatus()"></select2>
      </div>
      <div class="form-group" *ngIf="catalogItemData && catalogItemData.length>0">
        <label class="form-label">Productos</label>
        <select2 [(ngModel)]="productSelected" placeholder="Producto" [data]="catalogItemData" (update)="updateProduct()"></select2>
      </div>
    </div>
  </div>
  <div class="resume-points">
    <div class="title">PUNTOS CONSUMIDOS</div>
    <div class="points">{{totalPoints | number}}</div>
  </div>
</div>
<div class="resume-points mb-5">
  <button (click)="downloadExcel()" class="btn-dimgrey btn-icon btn-sm d-none mt-2 mt-sm-0" id="order-excel-bt">
    Descargar Excel
    <div class="svg-icon">
      <svg viewBox="0 0 150 150">
        <use xlink:href="assets/images/icons/icon-download.svg#img" href="assets/images/icons/icon-download.svg#img"></use>
      </svg>
    </div>
  </button>
  <table id="tblnews" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="nowrap data-tables responsive">
    <thead>
      <tr>
        <th class="text-center w-10" data-priority="4">Imagen</th>
        <th class="text-center w-10">Fecha</th>
        <th class="text-left" data-priority="1">Título</th>
        <th class="text-left" data-priority="3">Num. pedido</th>
        <th class="text-center w-10">Estado</th>
        <th *ngIf="miArea" class="text-center w-10" data-priority="2">Opciones</th>
        <th *ngIf="!miArea" class="text-center w-14">Entidad</th>
        <th class="text-center w-10">Unidades</th>
        <th class="text-center w-10">Puntos</th>
        <th *ngIf="isAdmin" class="text-center w-10">Puntos restantes</th>
        <th *ngIf="!isAdmin"></th>
      </tr>
    </thead>
  </table>
</div>
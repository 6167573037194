<footer (click)="toggleDevStyleClass($event)" class="main-footer">
  <div class="footer-menu">
    <div class="row">
      <div class="col-md-3 col-lg-2 mb-3">
        <a [routerLink]="['/']" routerLinkActive="active" aria-label="Renault Group">
          <img src="/assets/images/logo-renault-group-blanco.png" alt="Renault Group">
        </a>
      </div>
      @defer (on viewport) {
      <div class="col-md-9 col-lg-10">
        <div class="row">
          <ng-container *ngIf="products && products.length">
            <div class="col-sm-6 col-lg mb-3">
              <h5>Productos</h5>
              <ul class="footer-menu-links">
                <li *ngFor="let item of products"><a [routerLink]="['/products/detail/',item.Id]" routerLinkActive="active">{{item.Title}}</a></li>
                <li *ngIf="products.length == productsTake"><a [routerLink]="['/products/']" routerLinkActive="active">Ver más productos</a></li>
              </ul>
            </div>
          </ng-container>
          <ng-container *ngIf="services && services.length">
            <div class="col-sm-6 col-lg mb-3">
              <h5>Servicios</h5>
              <ul class="footer-menu-links">
                <li *ngFor="let item of services"><a [routerLink]="['/services/detail/',item.Id]" routerLinkActive="active">{{item.Title}}</a></li>
                <li *ngIf="services.length == servicesTake"><a [routerLink]="['/services/']" routerLinkActive="active">Ver más servicios</a></li>
              </ul>
            </div>
          </ng-container>
          <div class="col-sm-6 col-lg mb-5">
            <h5><a [routerLink]="['/como-conseguir-puntos']" routerLinkActive="active">Conseguir puntos</a></h5>
            <ng-container *ngIf="UserAuthenticated">
              <h5><a [routerLink]="['/promotions']" routerLinkActive="active">Promociones</a></h5>
              <h5><a [routerLink]="['/catalog']" routerLinkActive="active">Catálogo</a></h5>
            </ng-container>
            <h5><a [routerLink]="['/news']" routerLinkActive="active">Noticias</a></h5>
            <h5><a data-trigger="help" class="pointer">Punto de ayuda</a></h5>
            <button *ngIf="!UserAuthenticated" [routerLink]="['/login']" class="btn btn-light">Regístrate</button>
            <button *ngIf="UserAuthenticated" (click)="logout()" class="btn btn-light btn-icon">
              <div class="svg-icon">
                <svg viewBox="0 0 150 150">
                  <use xlink:href="assets/images/icons/icon-logout.svg#img" href="assets/images/icons/icon-logout.svg#img"></use>
                </svg>
              </div>
              Cerrar sesión
            </button>
          </div>
        </div>
      </div>
      } @placeholder {
      <div class="loader position-relative">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      }
    </div>
  </div>
  <!-- <div class="footer-contact">
    <div>Punto de ayuda: <a href="mailto:gestor@inforenaultpuntoapunto.com">gestor&#64;inforenaultpuntoapunto.com</a></div>
    <div>Horario: Lunes-Jueves 9:00-13:30h / 14:30-18:00h - Viernes 8:00-15:00h</div>
  </div> -->
  <div class="footer_legal">
    <div class="copy">© Renault Group {{ currentYear }}</div>
    <div class="legal_links">
      <a [routerLink]="['/informacion-legal']" class="link-light">
        Información legal
      </a>
      <a [routerLink]="['/politica-cookies']" class="link-light">
        Política de cookies
      </a>
    </div>
    <div *ngIf="isInDevEnvironment" class="show-indevelopment d-none d-xl-block position-relative flex-grow-1 text-center">
      <label class="position-absolute top-0 start-50 translate-middle text-nowrap mt-n1"><small>[Solo visible en desarrollo]</small></label>
      <a href="/develop/patterns" target="dev-patterns" class="link-light">
        Sistema diseño
      </a>
    </div>
  </div>
</footer>
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FileService {
    
  constructor(private http: HttpClient) { }

  public getFileDataTextByUrl(url: string): Observable<string> {
    return this.http.get<string>(url, { responseType: 'text' as 'json' });
  }
}

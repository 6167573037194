@for (item of Items; track item.Title; let i = $index) {
@if(item.SubItems.length) {
@if(item.Id != "menuMiArea")
{
<li ngbDropdown #myDropDown="ngbDropdown" class="nav-item dropdown hover" (click)="toggleMainMenuDropdown(myDropDown, $event);" (mouseenter)="openMainMenuDropdown(myDropDown)" (mouseleave)="overDropdown=false">
  <a role="button" class="nav-link dropdown-toggle" id="dropdownMenu{{ i }}" ngbDropdownToggle>
    {{ item.Title }}
  </a>
  <ul [ngClass]="{'disabled-dropdown': !overDropdown}" ngbDropdownMenu class="submenu dropdown-menu animate fade-down" [attr.aria-labelledby]="'dropdownMenu' + i" (mouseleave)="closeMainMenuDropdown(myDropDown)">
    @for (subitem of item.SubItems; track subitem.Title) {
    <li class="nav-item" id="{{ subitem.Id }}" [ngClass]="{ 'active': isRouteActive(subitem.Url) }">
      <a [routerLink]="[subitem.Url]" routerlinkactive="active" class="nav-link">{{ subitem.Title }}</a>
    </li>
    }
  </ul>
</li>
}@else
{
@for (subitem of item.SubItems; track subitem.Title) {
<li class="nav-item" id="{{ subitem.Id }}" [ngClass]="{ 'active': isRouteActive(subitem.Url) }">
  <a [routerLink]="[subitem.Url]" routerlinkactive="active" class="nav-link">{{ subitem.Title }}</a>
</li>
}
}

} @else {
<li class="nav-item" id="{{ item.Id }}"><a [routerLink]="[ item.Url ]" routerLinkActive="active" class="nav-link"> {{ item.Title }} </a></li>
}
}
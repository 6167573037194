import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import Swal from 'sweetalert2';
import { UserService } from '../../../services/user.service';
import { DistributorParams } from '../../../models/distributor-params';
import { PuntoapuntoDistributorDataModel } from '../../../models/distributor-model';

@Component({
  selector: 'app-distributor-common-filter',
  templateUrl: './distributor-common-filter.component.html'
})
export class DistributorCommonFilterComponent implements OnInit {

  @Input() multiDr = false;
  @Input() multiPvGroup = false;
  @Input() multiDistri = false;
  @Input() multiTaller = false;

  @Input() inputFilter: DistributorParams;
  hasInputFilter: boolean = false;

  @Input() hideDr = false;
  @Input() hidePvGroup = false;
  @Input() hideDistri = false;
  @Input() hideTaller = false;

  @Input() hideResetFilter = false;

  @Input() requireDr = false;
  @Input() requirePvGroup = false;
  @Input() requireDistri = false;
  @Input() requireTaller = false;

  @Input() selectOnChange = false;
  @Input() externalContainer = false;

  @Output() modelFilterEmit = new EventEmitter<DistributorParams>();
  @Output() modelInputFilterEmit = new EventEmitter<DistributorParams>();
  modelFilter: DistributorParams = new DistributorParams();

  @Output() resetEmit = new EventEmitter<DistributorParams>();

  distributorParams: DistributorParams = new DistributorParams();

  rolSelected;
  rolData: any;
  drSelected;
  drData: any;
  grupopvSelected;
  grouppvData: any;
  distributorSelected;
  distributorData: any;
  tallerSelected;
  tallerData: any;

  someSelected: boolean = false;

  userDistributors: PuntoapuntoDistributorDataModel[] = [];

  constructor(private userService: UserService,
    private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.loadUserDistributors();
    this.cd.detectChanges();
  }

  loadUserDistributors() {

    this.userService.getUserDistributors().subscribe(resp => {
      this.userDistributors = resp;      
      if (this.inputFilter) {
        this.hasInputFilter = true;
        this.distributorParams = this.inputFilter;
        this.modelFilter = this.inputFilter;
        this.loadRoles();
        this.emitInputFilter();
      } else {
        this.loadRoles();
      }

    }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Error al recuperar los datos',
        text: 'Ocurrió un error'
      });
    });
  }

  loadRoles(){
    const arrayUniqueByKey = [...new Map(this.userDistributors.map(item =>
      [item["IdRol"], item])).values()];

    this.rolData = arrayUniqueByKey.map((dr) => { return { value: dr.IdRol.toString(), label: dr.Rol } }).sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));

    if (this.rolData.length == 1) {
      this.rolSelected = [this.rolData[0].value];
    }
    else {
      if (!this.hasInputFilter || this.hasInputFilter && this.inputFilter.Rol.length == 0) {
        this.rolSelected = [];
      } else {
        this.rolSelected = this.inputFilter.Rol;
      }
    }

    this.selectRol();
  }

  loadDR() {

    this.distributorParams.Rol = this.modelFilter.Rol;

    this.drSelected = this.multiDr ? [] : "";

    const arrayUniqueByKey = [...new Map(this.userDistributors.filter(x =>
                                                                      this.distributorParams.Rol.length == 0 ||
                                                                      this.distributorParams.Rol.some(z => z == x.IdRol.toString())
                                                                    ).map(item => [item["DrParent"], item])).values()];

    this.drData = arrayUniqueByKey.map((dr) => { return { value: dr.DrParent?.toString() ?? "-1", label: dr.DrParent?.toString() ?? "Sin DR" } }).sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0));

    if (this.drData.length == 1 && this.hideDr == false) {
      this.drSelected = this.multiDr ? [this.drData[0].value] : this.drData[0].value;
    }
    else {
      if (!this.hasInputFilter || this.hasInputFilter && this.inputFilter.Dr.length == 0) {
        this.drSelected = this.multiDr ? [] : "";
      } else {
        this.drSelected = this.multiDr ? this.inputFilter.Dr : this.inputFilter.Dr[0].toString();
      }
    }

    this.selectDR();

  }

  loadPvGroup() {

    this.distributorParams.Rol = this.modelFilter.Rol;
    this.distributorParams.Dr = this.modelFilter.Dr;

    this.grupopvSelected = this.multiPvGroup ? [] : "";

    const arrayUniqueByKey = [...new Map(this.userDistributors.filter(x =>
                                    this.distributorParams.Rol.length == 0 ||
                                    this.distributorParams.Rol.some(z => z == x.IdRol.toString())
    ).map(item => [item["PvGroupParent"], item])).values()];

    this.grouppvData = arrayUniqueByKey.map((dr) => { return { value: dr.PvGroupParent?.toString() ?? "-1", label: dr.PvGroupParent?.toString() ?? "Sin Grupo", idRegion: dr.DrParent?.toString() ?? "-1" } }).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

    if (this.distributorParams.Dr.length > 0) {
      this.grouppvData = this.grouppvData.filter(x => this.distributorParams.Dr.some(z => z == x.idRegion))
    }

    if (this.grouppvData.length == 1 && this.hidePvGroup == false) {
      this.grupopvSelected = this.multiPvGroup ? [this.grouppvData[0].value] : this.grouppvData[0].value;
    }
    else {
      if (!this.hasInputFilter || this.hasInputFilter && this.inputFilter.PvGroup.length == 0) {
        this.grupopvSelected = this.multiPvGroup ? [] : "";
      } else {
        this.grupopvSelected = this.multiPvGroup ? this.inputFilter.PvGroup : this.inputFilter.PvGroup[0].toString();
      }
    }

    this.selectGroupPV();

  }

  loadDistributor() {

    this.distributorParams.Rol = this.modelFilter.Rol;
    this.distributorParams.Dr = this.modelFilter.Dr;
    this.distributorParams.PvGroup = this.modelFilter.PvGroup;

    const arrayUniqueByKey = [...new Map(this.userDistributors.filter(x =>
                        this.distributorParams.Rol.length == 0 ||
                        this.distributorParams.Rol.some(z => z == x.IdRol.toString())
    ).map(item => [item["BirParent"], item])).values()];

    this.distributorData = arrayUniqueByKey.map((dr) => { return { value: dr.BirParent, label: dr.BirParent + ' - ' + dr.SocialNameParent?.toString() ?? "Sin cuenta", idRegion: dr.DrParent?.toString() ?? "-1", idPvGroup: dr.PvGroupParent?.toString() ?? "-1" } }).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

    if (this.distributorParams.Dr.length > 0) {
      this.distributorData = this.distributorData.filter(x => this.distributorParams.Dr.some(z => z == x.idRegion))
    }

    if (this.distributorParams.PvGroup.length > 0) {
      this.distributorData = this.distributorData.filter(x => this.distributorParams.PvGroup.some(z => z == x.idPvGroup))
    }

    if (this.distributorData.length == 1 && this.hideDistri == false) {
      this.distributorSelected = this.multiDistri ? [this.distributorData[0].value] : this.distributorData[0].value;
    }
    else {
      if (!this.hasInputFilter || this.hasInputFilter && this.inputFilter.Distributor.length == 0) {
        this.distributorSelected = this.multiDistri ? [] : "";
      } else {
        this.distributorSelected = this.multiDistri ? this.inputFilter.Distributor : this.inputFilter.Distributor[0].toString();
      }
    }

    this.selectDistri();

  }

  loadTaller() {

    this.distributorParams.Rol = this.modelFilter.Rol;
    this.distributorParams.Dr = this.modelFilter.Dr;
    this.distributorParams.PvGroup = this.modelFilter.PvGroup;
    this.distributorParams.Distributor = this.modelFilter.Distributor;

    this.tallerData = this.userDistributors.filter(x =>
                        this.distributorParams.Rol.length == 0 ||
                        this.distributorParams.Rol.some(z => z == x.IdRol.toString())
    ).map((dr) => { return { value: dr.IdTresor.toString(), label: dr.IdTresor + ' - ' + dr.SocialName, idRegion: dr.DrParent?.toString() ?? "-1", idPvGroup: dr.PvGroupParent?.toString() ?? "-1", idDistributor: dr.BirParent } }).sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

    if (this.distributorParams.Dr.length > 0) {
      this.tallerData = this.tallerData.filter(x => this.distributorParams.Dr.some(z => z == x.idRegion))
    }

    if (this.distributorParams.PvGroup.length > 0) {
      this.tallerData = this.tallerData.filter(x => this.distributorParams.PvGroup.some(z => z == x.idPvGroup))
    }

    if (this.distributorParams.Distributor.length > 0) {
      this.tallerData = this.tallerData.filter(x => this.distributorParams.Distributor.some(z => z == x.idDistributor))
    }

    if (this.tallerData.length == 1 && this.hideTaller == false) {
      this.tallerSelected = this.multiTaller ? [this.tallerData[0].value] : this.tallerData[0].value;
    }
    else {
      if (!this.hasInputFilter || this.hasInputFilter && this.inputFilter.Taller.length == 0) {
        this.tallerSelected = this.multiTaller ? [] : "";
      } else {
        this.tallerSelected = this.multiTaller ? this.inputFilter.Taller : this.inputFilter.Taller[0].toString();
      }
    }
    this.hasInputFilter = false;
  }

  selectRol() {

    if (this.rolSelected !== undefined) {
      this.modelFilter.Rol = this.rolSelected;
    } else {
      this.modelFilter.Rol = [];
    }
    if (this.selectOnChange) this.emitFilter();
    this.loadDR();
    this.checkSelection();
  }

  selectDR() {
    if (this.drSelected !== undefined) {
      this.modelFilter.Dr = this.multiDr ? this.drSelected : [this.drSelected];
    } else {
      this.modelFilter.Dr = [];
    }
    if (this.selectOnChange) this.emitFilter();
    this.loadPvGroup();
    this.checkSelection();
  }

  selectGroupPV() {
    if (this.grupopvSelected !== undefined) {
      this.modelFilter.PvGroup = this.multiPvGroup ? this.grupopvSelected : [this.grupopvSelected];
    } else {
      this.modelFilter.PvGroup = [];
    }
    if (this.selectOnChange) this.emitFilter();
    this.loadDistributor();
    this.checkSelection();
  }

  selectDistri() {
    if (this.distributorSelected !== undefined) {
      this.modelFilter.Distributor = this.multiDistri ? this.distributorSelected : [this.distributorSelected];
    }
    else {
      this.modelFilter.Distributor = [];
    }
    if (this.selectOnChange) this.emitFilter();
    this.loadTaller();
    this.checkSelection();
  }

  selectTaller() {
    if (this.tallerSelected !== undefined) {
      this.modelFilter.Taller = this.multiTaller ? this.tallerSelected : [this.tallerSelected];
    }
    else {
      this.modelFilter.Taller = [];
    }
    if (this.selectOnChange) this.emitFilter();
    this.checkSelection();
  }

  cleanFilters() {
    this.modelFilter = new DistributorParams();
    this.rolSelected = [];
    this.drSelected = this.multiDr ? [] : "";
    this.grupopvSelected = this.multiPvGroup ? [] : "";
    this.distributorSelected = this.multiDistri ? [] : "";
    this.tallerSelected = this.multiTaller ? [] : "";
    this.someSelected = false;
    this.resetEmit.emit(this.modelFilter);
    this.loadDR();
  }

  emitFilter() {
    this.modelFilterEmit.emit(this.modelFilter);
  }

  emitInputFilter() {
    this.modelInputFilterEmit.emit(this.modelFilter);
  }

  emitReset() {
    this.cleanFilters();
  }

  checkSelection() {
    const fields = [this.rolSelected, this.drSelected, this.grupopvSelected, this.distributorSelected, this.tallerSelected];
    let someSelected = false;
    fields.forEach(f => {
      if (f && f.length > 0) {
          someSelected = true;
      }
    });
    this.someSelected = someSelected;
  }

}



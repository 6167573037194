import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Select2Data } from 'ng-select2-component';
import { environment } from '../../environments/environment';
import { MenuDTO } from '../models/menu-dto';


@Injectable({
  providedIn: 'root'
})

export class SharedService {

  private pathAPI = environment.urlAPI;
  private controller = "Shared/";

  public monthData = new BehaviorSubject<Select2Data>(null);
  public yearData = new BehaviorSubject<Select2Data>(null);

  constructor(private http: HttpClient) {

  }


  public getPuntoapuntoFilters(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoFilters');
  }

  public getPuntoapuntoRoles(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoRoles');
  }

  public getPuntoapuntoUsers(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoUsers');
  }

  public getPuntoapuntoEntityStatutes(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoEntityStatutes');
  }

  public getPuntoapuntoCRMTypes(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoCrmTypes');
  }
  
  public getPuntoapuntoFamilySegments(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoFamilySegments');
  }

  public getPuntoapuntoFamilies(idSegment: number): Observable<any[]> {
    return this.http.get<any[]>(this.pathAPI + this.controller + 'GetPuntoapuntoFamilies/' + idSegment);
  }

  public getPuntoapuntoRolForFamilyAnimation(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoRolForFamilyAnimation');
  }

  public getPuntoapuntoIncentiveSuccessFileType(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoIncentiveSuccessFileType');
  }

  public getPuntoapuntoIncentiveSuccessUserType(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoIncentiveSuccessUserType');
  }

  public getMenu(): Observable<MenuDTO> {
    return this.http.get<MenuDTO>(this.pathAPI + this.controller + 'GetMenu');
  }

  public getAdminMenu(): Observable<any> {
    return this.http.get<any>('assets/json/admin-menu.json');
  }

  public getAdminRenaultMenu(): Observable<any> {
    return this.http.get<any>('assets/json/admin-renault-menu.json');
  }

  public getFaqs(): Observable<any> {
    return this.http.get<any>('assets/json/faqs.json');
  }

  public getPuntoapuntoCustomStatus(idStatus: number[]): Observable<Select2Data> {
    return this.http.post<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoCustomStatus', idStatus);
  }

  public getPuntoapuntoRolesForCRM(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoRolesForCRM');
  }

  public getMonthsBBDD(): Observable<any> {
    return this.http.get<any>(this.pathAPI + this.controller + 'GetMonths');
  }
}

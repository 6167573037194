<ng-container *ngIf="isInDevEnvironment">
  <div class="container-fluid">
    <div class="row">
      <div class="col-2">
        <div class="sticky-top nav-bar">
          <h4>Estilos</h4>
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link" (click)="scrollTo('textos')">Texto</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="scrollTo('titulos')">Títulos</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="scrollTo('botones')">Botones</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="scrollTo('accordion')">Accordion</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-10">
        <div class="section_header">
          <div class="section_header_title">
            <h1>Estilos Renault Punto a punto</h1>
          </div>
          <div class="section_header_description">
            <p class="lead">Esto pretende ser un listado no exhaustivo de los estilos personalizados de la plataforma. Utilizamos Bootstrap 5 como base para las hojas de estilo de la plataforma, esto quiere decir que todos los estilos de Bootstrap 5 están disponibles. Para más información visita la <a href="https://getbootstrap.com/docs/5.3/getting-started/introduction/" target="_blank">documentación de Bootstrap</a></p>
          </div>

          <div class="alert alert-primary alert-withicon" role="alert">
            <div class="alert-icon">

            </div>
            <div class="alert-text">Es importante <strong>no sobrescribir en la medida de lo posibles las clases de Bootstrap</strong> al aplicar estilos. Sobre todo es imprescindible no modificar su utilidad. Si necesitamos modificar alguna propiedad de estas, la forma correcta de hacerlo es a través de sus <strong>variables</strong>.</div>
          </div>
        </div>

        <div id="textos" class="patterns-section">
          <h2 class="patterns-section-title">Textos</h2>
          <div class="patterns-subsection">
            <h1>h1. Renault Punto a punto heading</h1>
            <h2>h2. Renault Punto a punto heading</h2>
            <h3>h3. Renault Punto a punto heading</h3>
            <h4>h4. Renault Punto a punto heading</h4>
            <h5>h5. Renault Punto a punto heading</h5>
            <h6>h6. Renault Punto a punto heading</h6>

            <div class="patterns-code">
              <div>&lt;h1&gt;h1. Renault Punto a punto heading&lt;/h1&gt;</div>
              <div>&lt;h2&gt;h2. Renault Punto a punto heading&lt;/h2&gt;</div>
              <div>&lt;h3&gt;h3. Renault Punto a punto heading&lt;/h3&gt;</div>
              <div>&lt;h4&gt;h4. Renault Punto a punto heading&lt;/h4&gt;</div>
              <div>&lt;h5&gt;h5. Renault Punto a punto heading&lt;/h5&gt;</div>
              <div>&lt;h6&gt;h6. Renault Punto a punto heading&lt;/h6&gt;</div>
            </div>
          </div>
          <div class="patterns-subsection">
            <h1 class="txt_bar">h1. Renault Punto a punto heading</h1>
            <h2 class="txt_bar">h2. Renault Punto a punto heading</h2>

            <div class="patterns-code">
              <div>&lt;h1 class="txt_bar"&gt;h1. Renault Punto a punto heading&lt;/h1&gt;</div>
              <div>&lt;h2 class="txt_bar"&gt;h2. Renault Punto a punto heading&lt;/h2&gt;</div>
            </div>
          </div>
          <div class="patterns-subsection">
            <p>Este es un parrafo normal. Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque hic mollitia officiis porro dolores, ad dolor quisquam dolore modi expedita impedit dicta sunt inventore quasi? Repellendus libero in facilis necessitatibus.</p>
            <p class="lead">Este es un parrafo descatado. Sobresale sobre el resto de parrafos. Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident autem ullam numquam et distinctio consequatur totam enim. Deleniti ratione est sed sequi quisquam eaque officiis, debitis fuga nobis rerum natus.</p>
            <p><small>Este es un parrafo para letra pequeña. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate beatae quia atque ratione adipisci. Deserunt recusandae vitae, autem quibusdam veritatis expedita asperiores veniam deleniti aut suscipit ea vero itaque voluptatem?</small></p>
            <p><strong>Este es un parrafo en negrita. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perspiciatis, reiciendis. Porro reprehenderit ad quos? Ducimus possimus, error incidunt temporibus odit quo! Voluptatem distinctio dolorem accusamus illum sit qui doloribus ratione.</strong></p>
            <p><em>Este es un parrafo en itálica. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptates aperiam sit laudantium optio, ipsam sunt molestiae rerum ipsum nobis quasi accusamus nisi incidunt, voluptatibus nulla ullam at minus minima itaque?</em></p>
            <p class="text-center">Un parrafo alineado al centro. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque dolor quis corrupti odio neque, error maiores voluptate est soluta ducimus ea dicta, nihil adipisci. Voluptatem illum similique rerum neque velit?</p>
            <p class="text-end">Un parrafo alinedo a la derecha. Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit error quibusdam magni? Voluptatibus corrupti ab nostrum saepe fugiat laudantium, nisi labore possimus veniam porro ipsa recusandae quis, iste mollitia exercitationem!</p>
            <p id="plinks">Diferentes enlaces. <a href="#" (click)="scrollTo('plinks')" class="plain-link">Lorem ipsum</a> dolor sit amet consectetur <a href="#" (click)="scrollTo('plinks')">adipisicing elit</a>. Impedit error quibusdam magni? Voluptatibus corrupti ab nostrum saepe <a href="#" (click)="scrollTo('plinks')" class="highlighted-link">fugiat laudantium</a>, nisi labore possimus veniam porro ipsa recusandae quis, iste mollitia exercitationem!</p>

            <div class="patterns-code">
              <div>&lt;p&gt;Este es un parrafo normal.&lt;/p&gt;</div>
              <div>&lt;p class="lead"&gt;Este es un parrafo descatado. Sobresale sobre el resto de parrafos.&lt;/p&gt;</div>
              <div>&lt;p&gt;&lt;small&gt;Este es un parrafo para letra pequeña.&lt;/small&gt;&lt;/p&gt;</div>
              <div>&lt;p&gt;&lt;strong&gt;Este es un parrafo en negrita.&lt;/strong&gt;&lt;/p&gt;</div>
              <div>&lt;p&gt;&lt;em&gt;Este es un parrafo en itálica.&lt;/em&gt;&lt;/p&gt;</div>
              <div>&lt;p class="text-center"&gt;Un parrafo alineado al centro.&lt;/p&gt;</div>
              <div>&lt;p class="text-end"&gt;Un parrafo alinedo a la derecha.&lt;/p&gt;</div>
              <div>&lt;p class="text-end"&gt;Diferentes enlaces. &lt;a href="#"&gt;adipisicing elit&lt;/a&gt; - &lt;a href="#" class="highlighted-link"&gt;fugiat laudantium&lt;/a&gt; - &lt;a href="#" class="plain-link"&gt;nisi labore possimus&lt;/a&gt;&lt;/p&gt;</div>
            </div>
          </div>
        </div>


        <div id="titulos" class="patterns-section">
          <h2 class="patterns-section-title">Títulos</h2>
          <div class="patterns-subsection">

            <div class="section_header">
              <div class="section_header_title">
                <h2>Título de sección</h2>
                <div class="section_header_buttons"><a class="btn-dimgrey" href="#">Botón</a> <a class="btn-back" href="#">Volver</a></div>
              </div>
              <div class="section_header_description">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam bibendum leo quis euismod cursus. Quisque eget augue blandit, interdum leo eget, tempor metus. Sed.</p>
              </div>
            </div>

            <div class="patterns-code">
              <div>&lt;div class="section_header"&gt;</div>
              <div>&#160; &lt;div class="section_header_title"&gt;</div>
              <div>&#160; &#160; &lt;h2&gt;Título de sección&lt;/h2&gt;</div>
              <div>&#160; &#160; &lt;div class="section_header_buttons"&gt;</div>
              <div>&#160; &#160; &#160; &lt;a class="btn-dimgrey" href="#"&gt;Botón&lt;/a&gt;</div>
              <div>&#160; &#160; &#160; &lt;a class="btn-back" href="#"&gt;&lt;fa-icon [icon]="['fas','chevron-left']"&gt;&lt;/fa-icon&gt; Volver&lt;/a&gt;</div>
              <div>&#160; &#160; &lt;/div&gt;</div>
              <div>&#160; &lt;/div&gt;</div>
              <div>&#160; &lt;div class="section_header_description"&gt;</div>
              <div>&#160; &#160; &lt;p&gt;Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam bibendum leo quis euismod cursus.&lt;/p&gt;</div>
              <div>&#160; &lt;/div&gt;</div>
              <div>&lt;/div&gt;</div>
            </div>

          </div>
          <div class="patterns-subsection">

            <div class="section_header">
              <div class="section_header_title">
                <h2>Título de sección con alerta</h2>
                <div class="section_header_buttons"><a class="btn-dimgrey" href="#">Botón</a> <a class="btn-back" href="#">Volver</a></div>
              </div>
              <div class="section_header_description">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam bibendum leo quis euismod cursus. Quisque eget augue blandit, interdum leo eget, tempor metus. Sed.</p>
              </div>

              <div class="alert alert-primary alert-withicon" role="alert">
                <div class="alert-icon">

                </div>
                <div class="alert-text">
                  Lorem ipsum dolor sit amet, officia excepteur ex fugiat reprehenderit enim labore culpa sint ad nisi Lorem pariatur mollit ex esse exercitation amet. Nisi
                  animcupidatat excepteur officia. Reprehenderit nostrud nostrud ipsum Lorem est aliquip amet voluptate voluptate dolor minim nulla est proident.
                </div>
              </div>
            </div>

            <div class="patterns-code">
              <div>&lt;div class="section_header"&gt;</div>
              <div>&#160; &lt;div class="section_header_title"&gt;</div>
              <div>&#160; &#160; &lt;h2&gt;Título de sección con alerta&lt;/h2&gt;</div>
              <div>&#160; &#160; &lt;div class="section_header_buttons"&gt;</div>
              <div>&#160; &#160; &#160; &lt;a class="btn-dimgrey" href="#"&gt;Botón&lt;/a&gt;</div>
              <div>&#160; &#160; &#160; &lt;a class="btn-back" href="#"&gt;&lt;fa-icon [icon]="['fas','chevron-left']"&gt;&lt;/fa-icon&gt; Volver&lt;/a&gt;</div>
              <div>&#160; &#160; &lt;/div&gt;</div>
              <div>&#160; &lt;/div&gt;</div>
              <div>&#160; &lt;div class="section_header_description"&gt;</div>
              <div>&#160; &#160; &lt;p&gt;Reprehenderit nostrud nostrud ipsum Lorem est aliquip amet voluptate voluptate dolor minim nulla est proident.&lt;/p&gt;</div>
              <div>&#160; &lt;/div&gt;</div>
              <div>&lt;/div&gt;</div>
              <div>&lt;div class="alert alert-primary alert-withicon" role="alert"&gt;</div>
              <div>&#160;&lt;div class="alert-icon"&gt;</div>
              <div>&#160; &#160;&lt;fa-icon [icon]="['fas','circle-exclamation']"&gt;&lt;/fa-icon&gt;</div>
              <div>&#160;&lt;/div&gt;</div>
              <div>&#160;&lt;div class="alert-text"&gt;</div>
              <div>&#160; &#160; Lorem ipsum dolor sit amet, officia excepteur ex fugiat reprehenderit enim labore culpa sint ad nisi.</div>
              <div>&#160;&lt;/div&gt;</div>
              <div>&lt;/div&gt;</div>
            </div>

          </div>

        </div>

        <div id="botones" class="patterns-section">
          <h2 class="patterns-section-title">Botones</h2>
          <p>Las clases de los botones <strong>pueden aplicarse también a la etiqueta &lt;a href="#"&gt;&lt;/a&gt;</strong>. Para <strong>cambiar su tamaño</strong> pueden aplicarse las clases .btn-sm y .btn-lg</p>
          <div class="patterns-subsection">
            <div class="d-flex gap-3">
              <button type="button" class="btn-dimgrey">Dim Gray</button>
              <button type="button" class="btn-black">Black</button>
              <button type="button" class="btn-blue">Blue</button>
              <button type="button" class="btn-yellow">Yellow</button>
              <button type="button" class="btn-transparent">Transparent</button>
            </div>

            <div class="patterns-code">
              <div>&lt;button type="button" class="btn-dimgrey"&gt;Dim Gray&lt;/button&gt;</div>
              <div>&lt;button type="button" class="btn-black"&gt;Black&lt;/button&gt;</div>
              <div>&lt;button type="button" class="btn-blue"&gt;Blue&lt;/button&gt;</div>
              <div>&lt;button type="button" class="btn-yellow"&gt;Yellow&lt;/button&gt;</div>
              <div>&lt;button type="button" class="btn-transparent"&gt;Transparent&lt;/button&gt;</div>
            </div>
          </div>
          <div class="patterns-subsection">
            <div class="d-flex gap-3">
              <button type="button" class="btn-dimgrey-border">Dim Gray Border</button>
              <button type="button" class="btn-black-border">Black Border</button>
              <button type="button" class="btn-blue-border">Blue Border</button>
              <button type="button" class="btn-yellow-border">Yellow Border</button>
            </div>

            <div class="patterns-code">
              <div>&lt;button type="button" class="btn-dimgrey-border"&gt;Dim Gray Border&lt;/button&gt;</div>
              <div>&lt;button type="button" class="btn-black-border"&gt;Black Border&lt;/button&gt;</div>
              <div>&lt;button type="button" class="btn-blue-border"&gt;Blue Border&lt;/button&gt;</div>
              <div>&lt;button type="button" class="btn-yellow-border"&gt;Yellow Border&lt;/button&gt;</div>
            </div>
          </div>
          <div class="patterns-subsection">
            <div class="d-flex gap-3">
              <a [@loadedFadeAnimation]="'visible'" [routerLink]="['/login']" class="btn-signin">
                <div class="svg-icon"><svg viewBox="0 0 150 150">
                    <use xlink:href="assets/images/icons/icon-login.svg#img" href="assets/images/icons/icon-login.svg#img"></use>
                  </svg>
                </div> Identifícate
              </a>
            </div>

            <div class="patterns-code">
              <div>&lt;a [routerLink]="['/login']" class="btn-signin"&gt;</div>
              <div>&#160; &#160; &lt;div class="svg-icon"&gt;&lt;svg viewBox="0 0 150 150"&gt;</div>
              <div>&#160; &#160; &#160; &#160; &#160; &#160; &lt;use xlink:href="assets/images/icons/icon-login.svg#img" href="assets/images/icons/icon-login.svg#img"&gt;&lt;/use&gt;</div>
              <div>&#160; &#160; &#160; &#160; &lt;/svg&gt;</div>
              <div>&#160; &#160; &lt;/div&gt;</div>
              <div>&#160; &#160; Identifícate</div>
              <div>&lt;/a&gt;</div>
            </div>
          </div>
        </div>

        <div id="accordion" class="patterns-section">
          <h2 class="patterns-section-title">Accordion</h2>
          <div class="patterns-subsection">
            <div id="idAccordion" class="accordion accordion-flush">
              <div class="accordion-item">
                <h2 class="accordion-header" id="head0"><button type="button" data-bs-toggle="collapse" aria-expanded="false" class="accordion-button collapsed" data-bs-target="#accordion0"> Minima explicabo nihil exercitationem esse illo ullam. </button></h2>
                <div data-bs-parent="#idAccordion" class="accordion-collapse collapse" id="accordion0">
                  <div class="accordion-body">Magnam iure error enim nostrum eius impedit veniam blanditiis itaque. Temporibus accusamus consectetur sunt veritatis minus pariatur laudantium molestias. Nisi hic quo rem. Magnam iure error enim nostrum eius impedit veniam blanditiis itaque. Temporibus accusamus consectetur sunt veritatis minus pariatur laudantium molestias. Nisi hic quo rem. Magnam iure error enim nostrum eius impedit veniam blanditiis itaque. Temporibus accusamus consectetur sunt veritatis minus pariatur laudantium molestias. Nisi hic quo rem.</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="head1"><button type="button" data-bs-toggle="collapse" aria-expanded="false" class="accordion-button collapsed" data-bs-target="#accordion1"> Sed tempore autem numquam consequatur quisquam amet cupiditate esse est. </button></h2>
                <div data-bs-parent="#idAccordion" class="accordion-collapse collapse" id="accordion1">
                  <div class="accordion-body">Corporis distinctio cumque voluptatum harum architecto commodi. Reiciendis aut debitis corrupti dignissimos ipsam. Temporibus est cupiditate magnam suscipit repellendus voluptas quidem numquam natus.
                    Veritatis consequatur excepturi nemo eius. Voluptate fugiat neque praesentium explicabo ullam aliquam reprehenderit exercitationem. Expedita nam officiis sit consequuntur.
                    Quasi quia laborum eaque ullam et. Impedit laborum non sapiente saepe. Nihil iure numquam.</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="head2"><button type="button" data-bs-toggle="collapse" aria-expanded="false" class="accordion-button collapsed" data-bs-target="#accordion2"> Adipisci excepturi dolore nihil ipsam vero. </button></h2>
                <div data-bs-parent="#idAccordion" class="accordion-collapse collapse" id="accordion2">
                  <div class="accordion-body">Dolorem inventore cum. Aspernatur voluptate voluptatem dolore consectetur. Quae praesentium a dignissimos repudiandae est impedit nobis.
                    Sint modi quidem. Perferendis reiciendis fuga veritatis quas iusto qui error porro quas. Possimus consequuntur labore eos quae.
                    Earum dolores atque. Aperiam quod voluptatem nihil tempora atque unde. Officia saepe laboriosam enim praesentium facere qui explicabo aliquam.</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="head3"><button type="button" data-bs-toggle="collapse" aria-expanded="false" class="accordion-button collapsed" data-bs-target="#accordion3"> Excepturi quas nostrum doloribus asperiores id quod sed possimus quam. In illo non facilis libero temporibus similique. Consequatur assumenda officiis ab vero ducimus voluptas recusandae illo explicabo. </button></h2>
                <div data-bs-parent="#idAccordion" class="accordion-collapse collapse" id="accordion3">
                  <div class="accordion-body">Quas repellendus consequuntur minima. Magnam sed officiis. Enim quidem libero ut distinctio sapiente natus nobis.
                    Perspiciatis est doloribus consectetur repudiandae. Error porro impedit delectus repellendus cum ex eaque fugiat dolores. Sed inventore autem ad omnis id architecto sunt corporis animi.
                    Soluta perspiciatis earum voluptatem voluptates. Deserunt commodi incidunt sit aperiam aspernatur nostrum ducimus accusantium reiciendis. In in hic hic eaque eaque natus eveniet eaque doloribus.</div>
                </div>
              </div>
            </div>

            <div class="patterns-code">
              <div>&lt;div id="idAccordion" class="accordion accordion-flush"&gt;</div>
              <div>&#160; &lt;div class="accordion-item"&gt;</div>
              <div>&#160; &#160; &lt;h2 class="accordion-header" id="head0"&gt;</div>
              <div>&#160; &#160; &#160; &lt;button type="button" data-bs-toggle="collapse" aria-expanded="false" class="accordion-button collapsed" data-bs-target="#accordion0"&gt;Minima explicabo nihil exercitationem esse illo ullam.&lt;/button&gt;</div>
              <div>&#160; &#160; &lt;/h2&gt;</div>
              <div>&#160; &#160; &lt;div data-bs-parent="#idAccordion" class="accordion-collapse collapse" id="accordion0"&gt;</div>
              <div>&#160; &#160; &#160; &lt;div class="accordion-body"&gt;Magnam iure error enim nostrum eius impedit veniam blanditiis itaque...&lt;/div&gt;</div>
              <div>&#160; &#160; &lt;/div&gt;</div>
              <div>&#160; &lt;/div&gt;</div>
              <div>&#160; &lt;div class="accordion-item"&gt;</div>
              <div>&#160; &#160; &lt;h2 class="accordion-header" id="head1"&gt;</div>
              <div>&#160; &#160; &#160; &lt;button type="button" data-bs-toggle="collapse" aria-expanded="false" class="accordion-button collapsed" data-bs-target="#accordion1"&gt;Minima explicabo nihil exercitationem esse illo ullam.&lt;/button&gt;</div>
              <div>&#160; &#160; &lt;/h2&gt;</div>
              <div>&#160; &#160; &lt;div data-bs-parent="#idAccordion" class="accordion-collapse collapse" id="accordion1"&gt;</div>
              <div>&#160; &#160; &#160; &lt;div class="accordion-body"&gt;Magnam iure error enim nostrum eius impedit veniam blanditiis itaque...&lt;/div&gt;</div>
              <div>&#160; &#160; &lt;/div&gt;</div>
              <div>&#160; &lt;/div&gt;</div>
              <div>&lt;/div&gt;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
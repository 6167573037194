import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { Router } from '@angular/router';

import { Helpers } from '../../../helpers/helpers';

@Component({
  selector: 'app-patterns',
  templateUrl: './patterns.component.html',
  styleUrls: ['./patterns.component.scss']
})

export class PatternsComponent implements OnInit {

  isInDevEnvironment = false;

  constructor(
    private scroller: ViewportScroller,
    private router: Router,
    private helper: Helpers) {}

  ngOnInit() {
    this.isInDevEnvironment = this.helper.isInDevEnvironment();
    if(!this.isInDevEnvironment) {
      this.router.navigate(["/home"]);
    }
  }

  scrollTo(anchor: string) {
    this.scroller.setOffset([0,118]);
    this.scroller.scrollToAnchor(anchor);
    return false;
  }

}

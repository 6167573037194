import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataTablesResponse } from '../models/datatables';
import { FamilyGroupBySegmentModelDTO, PuntoapuntoFamilyDTO } from '../models/puntoapunto-family-dto';

@Injectable({
  providedIn: 'root'
})

export class FamilyService {

  private pathAPI = environment.urlAPI;
  private controller = "PuntoapuntoFamily/";

  constructor(private http: HttpClient) { }

  public get(id: string): Observable<PuntoapuntoFamilyDTO> {
    return this.http.get<PuntoapuntoFamilyDTO>(this.pathAPI + this.controller + id);
  }

  public post(data: PuntoapuntoFamilyDTO): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller, data);
  }

  public put(id: string, data: PuntoapuntoFamilyDTO): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + id, data);
  }

  public getDataTable(data: any, idSegment: number): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + this.controller + 'GetDataTable/' + idSegment, data);
  }

  public getFamilyGroupBySegment(): Observable<FamilyGroupBySegmentModelDTO[]> {
    return this.http.get<FamilyGroupBySegmentModelDTO[]>(this.pathAPI + this.controller + 'GetFamilyGroupBySegment');
  }

}

import { Router } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { PuntoapuntoUserTokenDTO } from '../models/puntoapunto-user-dto';
import { AuthJwtService } from '../services/auth-jwt.service';

export const authGuardPrivate = (route: ActivatedRouteSnapshot) => {

    const authJwtService = inject(AuthJwtService);
    const router = inject(Router);

    let token = authJwtService.getToken();
    let userData: PuntoapuntoUserTokenDTO = authJwtService.getUserDataManual(token);

    if (!userData) {
        router.navigate(['/home']);
        return false;
    }

    if (userData && !userData.ConditionAccept) {
        router.navigate(['/conditions-accept']);
        return false;
    }

    if (route.data["roles"]) {
        let rolValid = false;
        if (route.data["roles"].toString().split(",").some(r => userData.RolesListString.indexOf(r.toString().trim()) >= 0)) {
            rolValid = true;
        }
        if (rolValid === false) {
            router.navigate(['/home']);
            return false;
        }
    }
    return true;

};

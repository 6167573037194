import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { PuntoapuntoUserTokenDTO } from '../models/puntoapunto-user-dto';

@Injectable({
  providedIn: 'root'
})

export class AuthJwtService {

  private pathAPI = environment.urlAPI;
  private authenticationChanged = new Subject<boolean>();

  constructor(private http: HttpClient,
    private userService: UserService) {
  }

  public login(body: any): Observable<any> {
    let urlToken = this.pathAPI + 'Login';
    return this.http.post<any>(urlToken, body).pipe(
      tap(async (res: any) => {
        if (res.tokenPuntoapunto.length > 0) {
          this.setStorageToken(res.tokenPuntoapunto);
          this.setStorageRefreshToken(res.refreshTokenPuntoapunto);
          this.userService.initializeUserData();
        }
      })
    );
  }

  public loginPassword(body: any): Observable<any> {
    let urlToken = this.pathAPI + 'Login/LoginPassword';
    return this.http.post<any>(urlToken, body).pipe(
      tap(async (res: any) => {
        if (res.tokenPuntoapunto && res.tokenPuntoapunto.length > 0) {
          this.setStorageToken(res.tokenPuntoapunto);
          this.setStorageRefreshToken(res.refreshTokenPuntoapunto);
          this.userService.initializeUserData();
        }
      })
    );
  }

  public sendCode(body: any): Observable<any> {
    let urlToken = this.pathAPI + 'Login/SendCodeOTP';
    return this.http.post<any>(urlToken, body).pipe(
      tap(async (res: any) => {
        
      })
    );
  }

  public refreshToken(body: any): Observable<any> {

    let urlToken = this.pathAPI + 'Login/Refresh';
    return this.http.post<any>(urlToken, body).pipe(
      tap(async (res: any) => {
        if (res.tokenPuntoapunto.length > 0) {
          this.setStorageToken(res.tokenPuntoapunto);
          this.setStorageRefreshToken(res.refreshTokenPuntoapunto);
        }
      })
    );
  }

  public logout(): void {
    this.setStorageToken(undefined);
    this.setStorageRefreshToken(undefined);
    this.userService.setUserData(null);
  }

  public setToken(data: any): void {
    this.setStorageToken(data.tokenPuntoapunto);
    this.setStorageRefreshToken(data.refreshTokenPuntoapunto);
  }

  private setStorageToken(value: any): void {
    window.localStorage['tokenPuntoapunto'] = value;
    this.authenticationChanged.next(this.isAuthenticated());
  }

  private setStorageRefreshToken(value: any): void {
    window.localStorage['refreshTokenPuntoapunto'] = value;
  }

  public isAuthenticated(): boolean {

    return (!(window.localStorage['tokenPuntoapunto'] === undefined ||
      window.localStorage['tokenPuntoapunto'] === null ||
      window.localStorage['tokenPuntoapunto'] === 'null' ||
      window.localStorage['tokenPuntoapunto'] === 'undefined' ||
      window.localStorage['tokenPuntoapunto'] === ''));
  }

  public isAuthenticationChanged(): any {
    return this.authenticationChanged.asObservable();
  }

  public getToken(): any {
    if (window.localStorage['tokenPuntoapunto'] === undefined ||
      window.localStorage['tokenPuntoapunto'] === null ||
      window.localStorage['tokenPuntoapunto'] === 'null' ||
      window.localStorage['tokenPuntoapunto'] === 'undefined' ||
      window.localStorage['tokenPuntoapunto'] === '') {
      return '';
    }
    let obj = window.localStorage['tokenPuntoapunto'];
    return obj;
  }

  public getRefreshToken(): any {
    if (window.localStorage['refreshTokenPuntoapunto'] === undefined ||
      window.localStorage['refreshTokenPuntoapunto'] === null ||
      window.localStorage['refreshTokenPuntoapunto'] === 'null' ||
      window.localStorage['refreshTokenPuntoapunto'] === 'undefined' ||
      window.localStorage['refreshTokenPuntoapunto'] === '') {
      return '';
    }
    let obj = window.localStorage['refreshTokenPuntoapunto'];
    return obj;
  }

  public getUserDataManual(token): PuntoapuntoUserTokenDTO {
    if (token == null || token.trim() == '') return null;
    let jwt = JSON.stringify(token);
    return this.getTokenModel(jwt);
  }

  private getTokenModel(jwt): PuntoapuntoUserTokenDTO {
    if (jwt == null || jwt.trim() == '' || jwt.trim() == "") return null;
    let jwtData = jwt.split('.')[1]    
    let decodedJwtJsonData = this.b64DecodeUnicode(window.atob(jwtData));    
    let decodedJwtData = JSON.parse(decodedJwtJsonData);
    let userData = JSON.parse(decodedJwtData.UserData);    
    return userData;
  }

  private b64DecodeUnicode(str) {
    return decodeURIComponent(str.split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { UserAnimationPointDTO } from '../../../models/user-animation-point-dto';

@Component({
  selector: 'user-animation-sar-timeline',
  templateUrl: './user-animation-sar-timeline.component.html'
})
export class UserAnimationSarTimelineComponent implements OnInit {

  totalPointsSpent: number;
  totalPointsLost: number;
  totalPointsToLostThisYear: number = 0;

  constructor() {
  }

  @Input() userPoints: UserAnimationPointDTO;

  ngOnInit(): void {    
    this.getTotalPointsSpentLost();
  }

  getTotalPointsSpentLost() {
    this.totalPointsSpent = 0;
    this.totalPointsLost = 0;
    this.userPoints.PointYear.forEach(year => {
      this.totalPointsSpent += year.PointsSpent;
      this.totalPointsLost += year.PointsLost;
    });

    let lastYear = new Date().getFullYear() - 1;
    let lastYearData = this.userPoints.PointYear.find(x=>x.Year == lastYear);
    if(lastYearData){
      this.totalPointsToLostThisYear = lastYearData.PointsLeft;
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { UserAnimationPointDTO } from '../../../models/user-animation-point-dto';
import { UserService } from '../../../services/user.service';
import { DistributorParams } from '../../../models/distributor-params';
import { UserAnimationPointService } from '../../../services/user-animation-point.service';
import Swal from 'sweetalert2';
import { saveAs } from 'file-saver';

@Component({
  selector: 'user-animation-sar-points',
  templateUrl: './user-animation-sar-points.component.html',
})
export class UserAnimationSarPointsComponent implements OnInit {

  canViewDownload = false;
  isRecsa = false;

  constructor(private userService: UserService,
    private service: UserAnimationPointService
  ) {
  }

  @Input() userPoints: UserAnimationPointDTO;
  @Input() distributorParams: DistributorParams = null;

  ngOnInit(): void {
    this.getUserData();
  }

  getUserData() {
    this.userService.getUserData().subscribe(resp => {      
      if (resp) {        
        this.canViewDownload = resp.RolesListString.includes("Administrador") || resp.RolesListString.includes("AdminRecsa") || resp.RolesListString.includes("Recsa") || resp.RolesListString.includes("R1");
        this.isRecsa = resp.RolesListString.includes("Administrador") || resp.RolesListString.includes("AdminRecsa");
      }
    });
  }

  toggleAnimationDetail(id, ev) {
    let trigger = document.getElementById("data-trigger-" + id);
    let trTrigger = document.getElementById("tr-data-trigger-" + id);
    let trSubheader = document.getElementById("tr-subheader-row-" + id);
    let subrows = document.querySelectorAll("[data-detail-id='" + id + "']");
    let childSubrows = document.querySelectorAll("[data-detail-parent-id='" + id + "']");
    subrows.forEach(function (subrow) {
      if (subrow.classList.contains('closed')) {
        subrow.classList.remove('closed');
      } else {
        subrow.classList.add('closed');
      }
      let childTrigger = subrow.querySelector(".btn-expand-intable");
      if (childTrigger) {
        childTrigger.classList.add('collapsed');
      }
    });
    childSubrows.forEach(function (subrow) {
      subrow.classList.add('closed');
    });
    if (trigger.classList.contains('collapsed')) {
      trigger.classList.remove('collapsed');
      trTrigger.classList.remove('collapsed');
      trSubheader.classList.remove('collapsed');
    } else {
      trigger.classList.add('collapsed');
      trTrigger.classList.add('collapsed');
      trSubheader.classList.add('collapsed');
    }
  }

  toggleMonthDetail(id, ev) {
    let trigger = document.getElementById("data-trigger-" + id);
    let subrows = document.querySelectorAll("[data-detail-id='" + id + "']");
    subrows.forEach(function (subrow) {
      if (subrow.classList.contains('closed')) {
        subrow.classList.remove('closed');
      } else {
        subrow.classList.add('closed');
      }
    });
    if (trigger.classList.contains('collapsed')) {
      trigger.classList.remove('collapsed');
    } else {
      trigger.classList.add('collapsed');
    }
  }

  sortArrayBy(array, prop: string) {
    return array.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }

  downloadAnimation(animationStartDate: Date, animationEndDate: Date){

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor...'
    });

    Swal.showLoading();

    this.service.getUserAnimationPointsByAnimationExcel(this.distributorParams, animationStartDate, animationEndDate).subscribe(blob => {
      Swal.close();
      saveAs(blob, "PAP_InformeAnimacion.xlsx");
    }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Error al acceder a la BD',
        text: 'Ocurrió un error'
      });
    });

  }

  downloadInitialPoints(idInitialPointType: number, year: number){

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor...'
    });

    Swal.showLoading();

    this.service.getUserInitialPointsExcel(idInitialPointType, year).subscribe(blob => {
      Swal.close();
      saveAs(blob, "PAP_PuntosIniciales.xlsx");
    }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Error al acceder a la BD',
        text: 'Ocurrió un error'
      });
    });

  }
}

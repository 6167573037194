<ng-container *ngIf="data != null">
  <div [@loadedFadeAnimation]="'visible'" class="admin_subtitle">
    Estadísticas solicitud: {{data.Title}}
  </div>
  <div [@loadedFadeAnimation]="'visible'" class="statistics_campaign_dates">
    <span class="border-end pe-3"><i class="fa fa-calendar"></i> Fecha de creación: <strong>{{data.CreationDate | date:'dd/MM/yyyy'}}</strong></span>
    <span class="border-end pe-3"><i class="fa fa-calendar"></i> Fecha preferida de envío: <strong>{{data.PreferredDate | date:'dd/MM/yyyy HH:mm'}}</strong></span>
    <span><i class="fa fa-calendar"></i> Fecha de envío: <strong [ngClass]="{'date-diff': data.PreferredDate != data.SendDate}"><span class="send-date">{{data.SendDate | date:'dd/MM/yyyy HH:mm'}}</span></strong></span>
  </div>
  <div [@loadedFadeAnimation]="'visible'" class="statistics_campaign_data">
    <div class="col-auto">
      Tipo: <strong>{{data.CrmType}}</strong>
    </div>
    <div class="col-auto">
      Roles: <strong>{{data.RolesString}}</strong>
    </div>
    <div class="col-auto">
      Estado: <div [ngClass]="getStatusClass(data.Status)" class="order-status">{{data.Status}}</div>
    </div>
    <!-- <div class="col text-end">
      <button type="button" class="btn-dimgrey py-2" (click)="DownloadExcel()"><i class="bi bi-file-earmark-excel"></i> Exportar datos</button>
    </div> -->
  </div>
</ng-container>

<ng-container *ngIf="data != null">
  <div class="row statistics_chart mt-3 mb-5">
    <div [@loadedFadeAnimation]="'visible'" class="col-3xl-6">
      <div class="statistics_chart_small_title">Datos de conversión</div>
      <div class="bg-light funnel p-3 mb-3">
        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        <div *ngIf="data.Conts">
          <div class="row statistics_highlights">
            <div class="col statistics_data">
              <span class="statistics_data_title">Reactividad</span><br> {{reactivity | number}}%
            </div>
            <div class="col statistics_data">
              <span class="statistics_data_title">Bajas</span><br> {{data.Conts.Unsubscribe | number}} ({{unsubscribePorc | number}}%)
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [@loadedFadeAnimation]="'visible'" *ngIf="Html" class="col-3xl-6">
      <div class="statistics_chart_small_title">Previsualización HTML</div>
      <div class="border-img">
        <div [innerHtml]="Html"></div>
      </div>
    </div>
  </div>
</ng-container>
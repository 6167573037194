<div class="container pb-3">
  <h1 class="section-title">Cómo conseguir puntos</h1>
</div>
<div class="photo-header" data-header-height="748" data-header-img="assets/images/headers/puntos-header-min.png">
  <div class="visually-hidden">Sumar puntos</div>
</div>
<div class="container py-3">
  <div class="row">
    <div class="col p-3">
      <p class="lead-paragraph">
        Bienvenido a Punto a Punto, el programa de fidelización de Renault Group, el cual <strong>recompensa las compras de productos y accesorios Renault/Dacia de tus talleres</strong> con puntos para canjear por fantásticos regalos para ti y tu taller.
        El ámbito del Programa se extiende a todo el territorio nacional y <strong>la participación en el mismo es COMPLETAMENTE GRATUITA</strong>.
      </p>
    </div>
  </div>
  <div class="row photo-text-block photo-left">
    <div class="col-lg-5 col-xl-6 photo-block">
      <img src="/assets/images/conseguir-puntos/image_01-min.png" class="img-fluid" alt="Ser socio">
    </div>
    <div class="col-lg-7 col-xl-6 lead-text-block pt-5 pt-lg-0">
      <h3>Quién puede ser socio</h3>
      <p>En el programa participarán Agencias Renault/Dacia Servicio, Renault/Dacia Servicio, Talleres Motrio y Talleres Independientes. Todos ellos se podrán inscribir en el Programa a través del Concesionario / Reparador Autorizado de Renault y Dacia de su zona.
      </p>
      <p><strong>Quedan excluidas de participar en el Programa:</strong></p>
      <ul>
        <li>
          Las Agencias Renault/Dacia Servicio y Renault/Dacia Servicio sucursales y/o participados (con mayoría de control) de Concesionarios / Reparadores Autorizados.</li>
        <li>Las Agencias Renault/Dacia Servicio o Renault/Dacia Servicio que no estén al corriente de sus pagos por compras al Concesionario / Reparador Autorizado y con empresas del Grupo Renault.</li>
        <li>
          Los Talleres Independientes que no tengan actividad de reparación de vehículos automóviles.</li>
        <li>
          Los Talleres Independientes que sean distribuidores de piezas de recambio y accesorios.
        </li>
      </ul>
    </div>
  </div>
  <div class="row photo-text-block photo-right">
    <div class="col-lg-7 col-xl-6 lead-text-block">
      <h3>Ventajas</h3>
      <p>Al hacerte socio del Programa Punto a Punto podrás beneficiarte de las siguientes ventajas:</p>
      <ul>
        <li>
          La obtención de puntos por compras y la posibilidad de canjearlos por <strong>regalos</strong>.
        </li>
        <li>
          <strong>Promociones exclusivas</strong> que te permitirán ahorrar en tus compras de productos y accesorios y sumar puntos.
        </li>
        <li>
          Acceso al <strong>Catálogo de regalos Punto a Punto</strong>.
        </li>
        <li>
          Retos con los que conseguir <strong>experiencias y viajes exclusivos</strong>.
        </li>
        <li>
          <strong>Novedades e información</strong> relevante de tu interés.
        </li>
      </ul>
    </div>
    <div class="col-lg-5 col-xl-6 photo-block">
      <img src="/assets/images/conseguir-puntos/image_02-min.png" class="img-fluid" alt="Ventajas">
    </div>
  </div>

  <div [@loadedFadeAnimation]="'visible'" class="row bg-light pt-4 mb-5">
    <div class="col">
      <div class="container px-3">
        <div class="row">
          <div class="col text-center my-3">
            <h2 class="display-2 narrow-heading">Cómo sumar puntos y canjearlos por regalos</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6">
            <div class="lead-text-block py-4 px-0 px-md-4">
              <h4 class="mb-5">Hay dos formas de sumar puntos</h4>
              <ol class="bigdot-list">
                <li>
                  <p>Obtención de puntos por la compra de piezas de recambio y/o accesorios de las familias animadas.</p>
                  <div class="highlighted-text-box my-5">200 € de compra = <span class="text-nowrap">1 punto</span></div>
                  <!-- <div class="custom-button-group pt-3">
                    <a [routerLink]="['/products']" class="btn-black-border btn-icon">
                      Ver familias animadas
                      <div class="svg-icon">
                        <svg viewBox="0 0 150 150">
                          <use xlink:href="assets/images/icons/icon-download.svg#img" href="assets/images/icons/icon-download.svg#img"></use>
                        </svg>
                      </div>
                    </a>
                    <a [routerLink]="['/products']" class="btn-blue">
                      Ir a productos
                    </a>
                  </div> -->
                </li>
                <li>
                  <p>Obtención de puntos a través de <strong>promociones mensuales</strong>. En cada promoción se indica el número de puntos que se gana.</p>
                </li>
              </ol>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="lead-text-block p-ssm-4">
              <h4 class="mb-5">Condiciones</h4>
              <p>Los puntos acumulados del 1 de enero al 31 de diciembre {{ currentYear }} <strong>caducarán el 31 de diciembre {{ currentYear + 1 }}</strong>.</p>
              <p>Si no canjeas los puntos acumulados a la fecha de caducidad de los mismos, no los podrás utilizar, por lo que perderás el derecho a canjearlos.</p>
              <h4 class="my-5">Catálogo de regalos</h4>
              <p>Para canjear tus puntos solo tendrás que navegar por el <strong>catálogo de regalos Punto a punto y elegir lo que más te guste</strong>. Si dispones de puntos suficientes, podrás canjearlos al instante, ¡así de fácil!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!UserAuthenticated" class="row photo-text-block photo-left mt-5">
    <div class="col-lg-5 col-xl-6 photo-block">
      <img src="/assets/images/conseguir-puntos/image_03-min.png" class="img-fluid" alt="Ser socio">
    </div>
    <div class="col-lg-7 col-xl-6 lead-text-block">
      <h3>Inscripción del programa</h3>
      <p>La inscripción al Programa puede hacerse de dos formas:</p>
      <ul>
        <li>A través del <strong>Concesionario / Reparador Autorizado de la red Renault/Dacia*.</strong></li>
        <li>A través del formulario del <strong>apartado <a [routerLink]="['/login']" class="link-info">“Regístrate”</a> de esta web</strong>.</li>
      </ul>
      <p>* El interesado deberá estar dado de alta en la base de datos de TRESOR, Apolo y Rp&commat;rts. Una vez se revise tu solicitud, en los próximos días contactará contigo tu Concesionario/Reparador Autorizado Renault/Dacia de tu zona.</p>
      <p><strong>La inscripción se hace efectiva una vez que aceptes las bases del programa</strong> en la URL indicada en el email de confirmación que recibirás con el alta.</p>
    </div>
  </div>

  <div id="faqs">
    <div *ngIf="faqs.length>0" [@loadedFadeAnimation]="'visible'" class="row pt-sm-4 mb-5">
      <div class="col">
        <div class="container px-0 px-md-3">
          <div class="row">
            <div class="col text-center mt-3 mb-4">
              <h2 class="display-1 narrow-heading">FAQS</h2>
            </div>
          </div>
          <div class="row">
            <div ngbAccordion [closeOthers]="true" class="accordion-flush accordion-number px-0 px-md-3">
              <div *ngFor="let elem of faqs" ngbAccordionItem [collapsed]="elem.collapsed">
                <h2 ngbAccordionHeader>
                  <button ngbAccordionButton [innerHTML]="elem.title"></button>
                </h2>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-template>
                      <div [innerHTML]="elem.content"></div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
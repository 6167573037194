import { Routes } from '@angular/router';
import { authGuardPrivate } from '../../guards/auth.guard-private';

export const myzoneroutes: Routes = [
    { path: 'my-info', loadChildren: () => import('./my-info/my-info.module').then(m => m.MyInfoModule), data: { animation: 'user' }, canActivate: [authGuardPrivate] },
    { path: 'points-and-activity', loadChildren: () => import('./points_and_activity/points_and_activity.module').then(m => m.PointsAndActivityModule), data: { animation: 'user', roles: 'Administrador,AdminRecsa,R2,R3,MOTRIO' }, canActivate: [authGuardPrivate] },
    { path: 'points-and-activity/:section', loadChildren: () => import('./points_and_activity/points_and_activity.module').then(m => m.PointsAndActivityModule), data: { animation: 'user', roles: 'Administrador,AdminRecsa,R2,R3,MOTRIO' }, canActivate: [authGuardPrivate] },
    { path: 'admin-points-and-activity', loadChildren: () => import('./admin-points-and-activity/points-and-activity.module').then(m => m.AdminPointsAndActivityModule), data: { animation: 'pa', roles: 'Administrador,AdminRecsa,Recsa,R1' } },
    { path: 'admin-points-and-activity/:section', loadChildren: () => import('./admin-points-and-activity/points-and-activity.module').then(m => m.AdminPointsAndActivityModule), data: { animation: 'pa', roles: 'Administrador,AdminRecsa,Recsa,R1' } },
]

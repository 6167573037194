import { Component, OnInit, Input } from '@angular/core';
import { AuthJwtService } from '../../../services/auth-jwt.service';
import { PromotionFilter, PuntoapuntoPromotionDTO } from '../../../models/puntoapunto-promotion-dto';
import { PromotionService } from '../../../services/promotion.service';
import { visibleContentAnimation } from '../../../animations';

@Component({
  selector: 'app-prefooter',
  templateUrl: './prefooter.component.html',
  animations: [visibleContentAnimation]
})

export class PrefooterComponent implements OnInit {

  UserAuthenticated = false;

  @Input() modulesSet: 'void' | 'home' | 'login' | 'inner' | 'help';

  dataPromotion: PuntoapuntoPromotionDTO[] = [];
  dataAllPromotion: PuntoapuntoPromotionDTO[] = [];
  filter: PromotionFilter = new PromotionFilter();
  animatedFamiliesLink: string = null;

  constructor(
    private authJwtService: AuthJwtService,
    private service: PromotionService
  ) {
    this.UserAuthenticated = this.authJwtService.isAuthenticated();
  }

  ngOnInit(): void {
    this.authJwtService.isAuthenticationChanged().subscribe(resp => {
      this.UserAuthenticated = resp;
      if (this.UserAuthenticated) {
        this.loadDataAllPromotion();
      }
    });

    if (this.UserAuthenticated) {
      this.loadDataAllPromotion();
    }
  }

  loadDataAllPromotion() {
    this.filter.Skip = 0;
    this.filter.Take = 100;
    this.service.GetPuntoapuntoAllPromotionLst(this.filter).subscribe(resp => {
      const filteredData = resp.filter(item => item.AddPoints === true); // Filtrar por ShowHome igual a false (GetPuntoapuntoAllPromotionLst)
      this.dataAllPromotion = filteredData; // Reemplaza los datos actuales con los nuevos datos filtrados
      if (filteredData != null && filteredData.length == this.filter.Take) {
        this.filter.Skip = this.filter.Take;
      }
      this.animatedFamiliesLink = '/promotions/detail/' + this.dataAllPromotion[0].IdPromotion
    }, (err) => {
      this.animatedFamiliesLink = '/promotions'
    });
  }
}

/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  window.console.log = function (): void { };
  window.console.table = function (): void { };
  window.console.debug = function (): void { };
  window.console.warn = function (): void { };
  window.console.info = function (): void { };
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));

import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { heightContentAnimation, visibleContentAnimation } from '../../animations';

@Component({
  templateUrl: './legal-text.component.html',
  animations: [visibleContentAnimation, heightContentAnimation]
})

export class LegalTextComponent implements OnInit {

  prefooterModulesSet = 'void';
  breadcrumbs = [
    {
      text: 'Inicio',
      link: '/home'
    },
    {
      text: 'Información legal',
      link: '/informacion-legal'
    }
  ];
  currentDate = new Date();
  currentYear = parseInt(this.datepipe.transform(this.currentDate, 'yyyy'));

  constructor(
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
  }
}

import { PuntoapuntoFileDTO } from "./puntoapunto-file-dto";


export class PuntoapuntoPromotionDTO {
    IdPromotion: string | null;
    IdType: number;
    Type: string;
    AddPoints: boolean;
    Status: boolean;
    PublishDate: string;
    UnpublishDate: string | null;
    CreationDate: string;
    ValidInitDate: string | null;
    ValidEndDate: string | null;
    Title: string;
    Description: string;
    ShowHome: boolean;
    ImageFile: PuntoapuntoFileDTO | null;
    HtmlFile: PuntoapuntoFileDTO | null;
    PositiveFilters: string[];
    NegativeFilters: string[];
}

export class PuntoapuntoPromotionPostDTO {
    Status: boolean;
    IdType: number;
    PublishDate: string | null;
    UnpublishDate: string | null;
    ValidInitDate: string | null;
    ValidEndDate: string | null;
    Title: string;
    Description: string;
    ShowHome: boolean;
    ImageFile: File | null;
    HtmlFile: File | null;
    PositiveFilters: string[] | null;
    NegativeFilters: string[] | null;
}

export class PromotionFilter {
    Take: number;
    Skip: number;
}

export class PuntoapuntoPromotionLogDTO {
    IdPromotion: string;
}

export class PuntoapuntoPromotionFamilyDTO {
    IdSegment: number;
    Segment: string;
    IdFamily: number;
    Family: string;
    Description: string;
    IdRol: number;
    Reference: string;
    Points: number;
    Euros: number;
}
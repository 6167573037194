import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class CustomValidators {

  sameFilterValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.controls['PositiveFilters'];
      const control2 = group.controls['NegativeFilters'];
      control2.setErrors(null);
      if (control1.value != null && control2.value != null) {
        let intersection = control1.value.filter(x => control2.value.includes(x));
        if (intersection.length > 0) {
          control2.setErrors({ 'samefilter': true });
        }
      }
      return null;
    };
  }

  equalValueValidator(fieldName1, fieldName2): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.controls[fieldName1];
      const control2 = group.controls[fieldName2];
      if (control1.value != null && control2.value != null) {
        if(control1.value != control2.value) {
          control2.setErrors({ 'notequal': true });
        }
      }
      return null;
    }
  }

}

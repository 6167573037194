import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ChangeOrderDTO } from '../models/change-order-dto';
import { DataTablesResponse } from '../models/datatables';
import { Select2Data } from 'ng-select2-component';
import { CatalogFilter, PuntoapuntoCatalogItemCategoryDTO, PuntoapuntoCatalogItemDTO, PuntoapuntoCatalogItemIdDTO, PuntoapuntoCatalogItemLogDTO, PuntoapuntoCatalogItemOrderDTO, PuntoapuntoCatalogItemOrderLastOrderDTO, PuntoapuntoCatalogItemRatingDTO, PuntoapuntoCatalogProviderDTO } from '../models/puntoapunto-catalog-item-dto';


@Injectable({
  providedIn: 'root'
})

export class CatalogService {

  private pathAPI = environment.urlAPI;
  private controller = "PuntoapuntoCatalog/";

  constructor(private http: HttpClient) { }

  public get(id: string): Observable<PuntoapuntoCatalogItemDTO> {
    return this.http.get<PuntoapuntoCatalogItemDTO>(this.pathAPI + this.controller + id);
  }

  public getByFilterLst(filter: CatalogFilter): Observable<PuntoapuntoCatalogItemIdDTO[]> {
    return this.http.post<PuntoapuntoCatalogItemIdDTO[]>(this.pathAPI + this.controller + "GetPuntoapuntoAllCatalogLst/", filter);
  }

  public post(data: FormData): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller, data);
  }

  public put(id: string, data: FormData): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + id, data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + 'ChangeStatus/' + id + '/' + idStatus, null);
  }

  public removeFile(idFile: string): Observable<any> {
    return this.http.delete<any>(this.pathAPI + this.controller + 'RemoveFile/' + idFile);
  }

  public changeOrderFile(data: ChangeOrderDTO[]): any {
    return this.http.post<any>(this.pathAPI + this.controller + 'ChangeOrderFile', data);
  }

  public getDataTable(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + this.controller + 'GetDataTable', data);
  }

  public getDataTableOrderFile(data: any, IdItem: string): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + this.controller + 'GetDataTableOrderFile/' + IdItem, data);
  }

  public getPuntoapuntoCatalogItemCategoryRepository(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoCatalogItemCategoryRepository');
  }

  public getPuntoapuntoCatalogItemCategoryRepositoryActive(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoCatalogItemCategoryRepositoryActive');
  }

  public getPuntoapuntoCatalogProviderRepository(): Observable<any> {
    return this.http.get<any>(this.pathAPI + this.controller + 'GetPuntoapuntoCatalogProviderRepository');
  }

  public getCategory(id: string): Observable<PuntoapuntoCatalogItemCategoryDTO> {
    return this.http.get<PuntoapuntoCatalogItemCategoryDTO>(this.pathAPI + this.controller + "GetByIdCategory/" + id);
  }

  public postCategory(data: PuntoapuntoCatalogItemCategoryDTO): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller + "InsertCategory", data);
  }

  public putCategory(id: string, data: PuntoapuntoCatalogItemCategoryDTO): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + "UpdateCategory/" + id, data);
  }

  public getDataTableCategory(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + this.controller + 'GetDataTableCategory', data);
  }

  public getProvider(id: string): Observable<PuntoapuntoCatalogProviderDTO> {
    return this.http.get<PuntoapuntoCatalogProviderDTO>(this.pathAPI + this.controller + "GetByIdProvider/" + id);
  }

  public postProvider(data: PuntoapuntoCatalogProviderDTO): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller + "InsertProvider", data);
  }

  public putProvider(id: string, data: PuntoapuntoCatalogProviderDTO): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + "UpdateProvider/" + id, data);
  }

  public getDataTableProvider(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + this.controller + 'GetDataTableProvider', data);
  }

  public getRatingByCatalogItemAndUserId(idCatalogItem: string): Observable<PuntoapuntoCatalogItemRatingDTO> {
    return this.http.get<PuntoapuntoCatalogItemRatingDTO>(this.pathAPI + this.controller + 'GetRatingByCatalogItemAndUserId/' + idCatalogItem);
  }

  public postRating(data: PuntoapuntoCatalogItemRatingDTO): Observable<any> {
    return this.http.post<PuntoapuntoCatalogItemRatingDTO>(this.pathAPI + this.controller + "AddRating", data);
  }

  public putRating(id: string, data: PuntoapuntoCatalogItemRatingDTO): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + "UpdateRating/" + id, data);
  }

  public GetPuntoapuntoCatalogMinMaxPoints(): Observable<any> {
    return this.http.get<any>(this.pathAPI + this.controller + 'GetPuntoapuntoCatalogMinMaxPoints');
  }

  public postCatalogOrder(data: PuntoapuntoCatalogItemOrderDTO): Observable<any> {
    return this.http.post<PuntoapuntoCatalogItemOrderDTO>(this.pathAPI + this.controller + "InsertCatalogOrder", data);
  }

  public getCatalogItemOrderDatatable(data: any, filter: string): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + this.controller + 'GetCatalogItemOrderDatatable/' + filter, data);
  }

  public GetOrdersYears(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetOrdersYears');
  }


  public GetTotalPointsOrders(filter: string): Observable<any> {
    return this.http.get<any>(this.pathAPI + this.controller + 'GetTotalPointsOrders/' + filter);
  }

  public GetLastOrderByUser(): Observable<PuntoapuntoCatalogItemOrderLastOrderDTO> {
    return this.http.get<PuntoapuntoCatalogItemOrderLastOrderDTO>(this.pathAPI + this.controller + 'GetLastOrderByUser/');
  }

  public CancelOrder(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + 'CancelOrder/' + id + '/' + idStatus, null);
  }

  public GetPuntoapuntoCatalogItemSelector(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoCatalogItemSelector');
  }

  public GetPuntoapuntoCatalogItemOrderUserSelector(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoCatalogItemOrderUserSelector');
  }

  public postLog(data: PuntoapuntoCatalogItemLogDTO): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller + "InsertLog", data);
  }

  public getCatalogOrderExcel(year: number): Observable<Blob> {
    let requestOptions: Object = {
      responseType: 'blob'
    }
    let endpoint = this.pathAPI + this.controller + 'GetCatalogOrdersExcel/' + year;
    return this.http.get<Blob>(endpoint, requestOptions);
  }

  public CancelOrderAdmin(id: string): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + 'CancelOrderAdmin/' + id, null);
  }

}

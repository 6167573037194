import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  templateUrl: './cookies-policy.component.html'
})

export class CookiesPolicyComponent implements OnInit {

  prefooterModulesSet = 'void';
  breadcrumbs = [
    {
      text: 'Inicio',
      link: '/home'
    },
    {
      text: 'Política de cookies',
      link: '/politica-cookies'
    }
  ];
  currentDate = new Date();
  currentYear = parseInt(this.datepipe.transform(this.currentDate, 'yyyy'));

  constructor(
    public datepipe: DatePipe
  ) { }

  ngOnInit(): void {
  }
}

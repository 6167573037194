import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { visibleContentAnimation } from '../../../animations';
import { Helpers } from '../../../helpers/helpers';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Router,IsActiveMatchOptions } from '@angular/router';


@Component({
  selector: '[app-menu]',
  templateUrl: './menu.component.html',
  animations: [visibleContentAnimation]
})
export class MenuComponent implements OnInit {

  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;
  @Input() Items!: any[];


  currentInnerWidth: number;
  prevInnerWidth: number;

  isInDevEnvironment = true;

  overDropdown = false;

  constructor(private helper: Helpers,private router: Router) {
    
  }

  ngOnInit(): void {
    this.isInDevEnvironment = this.helper.isInDevEnvironment();
  }

  toggleMainMenuDropdown(dropdown: NgbDropdown, event) {
    // Close all dropdowns
    this.dropdowns.toArray().forEach(el => {
      if (dropdown != el) {
        el.close();
      }
    });
    if (dropdown._open || (event.pointerType == 'touch' && window.innerWidth >= 992)) {
      dropdown.open();
    } else {
      dropdown.close();
    }
    this.overDropdown = true;
  }

  openMainMenuDropdown(dropdown: NgbDropdown) {
    if (window.innerWidth >= 992) {
      // Close all dropdowns
      this.dropdowns.toArray().forEach(el => {
        if (dropdown != el) {
          el.close();
        }
      });
      // Open the dropdown that was hovered on
      dropdown.open();
      this.overDropdown = true;
    }
  }

  closeMainMenuDropdowncloseMainMenuDropdown(dropdown: NgbDropdown) {
    if (window.innerWidth >= 992) {
      // Close the dropdown that was hovered off
      if (this.overDropdown == false) {
        dropdown.close();
      }
    }
  }

  closeMainMenuDropdown(dropdown: NgbDropdown) {
    if (window.innerWidth >= 992) {
      // Close the dropdown that was hovered off
      if (this.overDropdown == false) {
        dropdown.close();
      }
    }
  }

  isRouteActive(url: string): boolean {
    const options: IsActiveMatchOptions = { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' };
    return this.router.isActive(url, options);
  }
}

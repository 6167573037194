<div class="container pb-3">
  <h2 class="section-title">Información legal</h2>
</div>
<div class="container legal-text-container legal-text">
  <div class="row">
    <div class="col">
      <div class="container legal-text-container legal-text">
        <p class="ordered-header">INFORMACIONES GENERALES</p>
        <p>RENAULT ESPAÑA COMERCIAL S.A. (en adelante, “RENAULT”) con domicilio en Parque Empresarial Avenida de Europa, Avenida de Europa nº 1, Edificio A, 28108-ALCOBENDAS (Madrid) y C.I.F. A-47.329.180 ha abierto esta web www.puntoapuntorenault.com (© Renault Group 2024) para la información personal de sus usuarios. La utilización por su parte de la web queda sujeta al cumplimiento por la información legal detallada más adelante y al conjunto de leyes aplicables. Desde el momento en que usted decida acceder a la web y navegue por ella y la utilice, usted está aceptando sin reserva las Informaciones Generales, que prevalecen sobre cualquier otro acuerdo que pudiera tener por otro lado con RENAULT o los miembros de su red comercial.<br>
          Responsable del site: Iban Ansotegui.<br>
          Esta sociedad está inscrita en: R.M. Valladolid, Hoja VA-4629, Folio 166, Tomo 512, Insc 1ª.<br>
          Centro Servidor de esta página web: Azure Web Services (Francia Central).<br>
          Esta página web está editada por: Renault y por Grupo SIM.<br>
        </p>
        <p class="ordered-header">DERECHOS DE PROPIEDAD INTELECTUAL</p>
        <p>
          <strong>Derechos de autor y/o Derechos sobre los Diseños y Modelos</strong><br>
          La presente web constituye una obra de la que RENAULT es el autor según los artículos 5 y siguientes del Texto Refundido de la Ley de Propiedad Intelectual (R.D. Leg. 1/1996). Las fotografías, textos, eslóganes, diseños, imágenes, secuencias animadas sonoras o no, así como todas las obras integradas en la web son propiedad de RENAULT o de terceros que han autorizado a RENAULT para utilizarlos.<br>
          Las reproducciones, en soporte papel o informático, de todo o parte del contenido del citado site están autorizadas bajo la condición de que su uso sea estrictamente personal, que excluya todo uso con fines publicitarios y/o comerciales, o que no se contemplen dentro de los usos autorizados en los artículos 31 a 40 bis del Texto Refundido de la Ley de Propiedad Intelectual (R.D. Leg. 1/1996). A excepción de estos supuestos, toda reproducción, representación, utilización o modificación, para cualquier proceso y sea cual sea el soporte, de toda o parte de la web, de todas o parte de las obras y de los modelos de los vehículos que lo componen, sin la previa autorización de RENAULT, está estrictamente prohibida y constituye un delito tipificado en los artículos 270 y 271 del vigente Código Penal, con penas de hasta cuatro años de prisión y 24 meses de multa, sin perjuicio de la responsabilidad civil derivada del citado delito conforme a los establecido en los artículos 138 y siguientes del Texto Refundido de la Ley de Propiedad Intelectual (R.D. Leg. 1/1996).
        </p>
        <p><strong>Derecho de marcas</strong><br>
          En la web se citan otras marcas, que en principio se identifican por una mayúscula, y que son utilizadas por RENAULT ya sea con la autorización de su titular, bien como simple indicación de productos o servicios propuestos por RENAULT. La reproducción, imitación, utilización o inserción de estas marcas sin la anterior autorización de RENAULT o de sus titulares respectivos constituye un delito tipificado en los artículos 274 y 276 del vigente Código Penal, penado con hasta cuatro años de prisión y hasta 24 meses de multa.<br>
        </p>
        <p class="ordered-header">INFORMACIÓN LEGAL SOBRE PRODUCTOS Y SERVICIOS</p>
        <p>La información contenida en esta web constituye una presentación general de la marca “puntoapunto” y de los productos y los servicios de “puntoapunto” en España.</p>
        <p><strong>Piezas de recambio y accesorios</strong><br>
          RENAULT comercializa en España las piezas de recambio y accesorios originales RENAULT/DACIA a través de la Red de Concesionarios RENAULT. Usted puede consultar la relación de Concesionarios RENAULT actualizada a través de los contactos RENAULT facilitados en este site.<br>
          La información e ilustraciones sobre las piezas de recambio y accesorios presentados en este sitio web se basan en las características vigentes en el momento de la publicación en el sitio web o de las respectivas actualizaciones de las distintas páginas del sitio web. El fabricante se reserva el derecho de efectuar modificaciones en los accesorios originales RENAULT, o de las marcas que RENAULT tenga autorizado promocionar, o incluso de cesar en su fabricación sin necesidad de aviso previo.<br>
          RENAULT realiza sus mayores esfuerzos para trasladar a la información contenida en esta web cualquier novedad que sobre las piezas de recambio y accesorios originales RENAULT, o de las marcas que esté autorizado a promocionar, introduzca su fabricante en el proceso de fabricación y para eliminar de esta web toda información respecto de un producto determinado tan pronto tiene conocimiento de la inexistencia de unidades disponibles par venta. No obstante, por la inmediatez de la interactuación del Usuario con el contenido de este site, sería posible que en un momento determinado las existencias de una pieza de recambio o accesorio , aún incluido en este site, se encontrasen agotadas.</p>
        <p><strong>Servicios</strong><br>
          Los servicios, de promover un servicio de fidelización de los Usuarios mediante acceso a la información y consulta sobre piezas de recambio y accesorios, así como a las ventajas asociadas a su compra, presentados en el site son propuestos por RENAULT a los Usuarios participantes de la red RENAULT.</p>
        <p class="ordered-header">GESTIÓN DE PASSWORDS Y CODIGOS DE ACCESO</p>
        <p>En el proceso de inscripción ud. introduce su correo electrónico y de forma automática recibe un código vía email. Se ha implementado un proceso de autenticación mediante código de un solo uso (OTP). Este código de un sólo uso es válido por 60 minutos desde el momento en que se genera. Después de ese tiempo, el código expirará y será necesario solicitar uno nuevo. Se responsabiliza de informar inmediatamente a RENAULT de todo uso no autorizado de su cuenta y/o de su contraseña, así como de desconectar el acceso en cada sesión. RENAULT no podrá ser considerado responsable en caso de pérdida o problema causado por la pérdida o incumplimiento de las obligaciones</p>
        <p class="ordered-header">DATOS DE CARÁCTER PERSONAL Y OTROS DATOS. COOKIES</p>
        <p>RENAULT ESPAÑA COMERCIAL, S.A., está muy concienciada con la protección de los datos personales. Le recomendamos consultar nuestra Política de Privacidad (<a href="https://www.renault.es/politica-privacidad.html" target="_blank">Política de Privacidad</a>) y Política de Cookies (<a href="/politica-cookies">Política de Cookies</a>) que establecen de manera transparente cómo se tratan y protegen sus datos personales antes de completar cada uno de los cuestionarios o formularios de esta web.</p>
        <p>En el site existen cuestionarios o formularios a través de los cuales se solicita al usuario datos (de carácter personal o no). Los datos que sea obligatorio proporcionar estarán especificados de ese modo (*) en dichos cuestionarios o formularios. La información relativa a los responsables/corresponsables del tratamiento, las finalidades del tratamiento, su base legitimadora, los destinatarios de los datos y el ejercicio de derechos (acceso, supresión, limitación de tratamiento, oposición, portabilidad y/o rectificación) podrá consultarse antes de completar cada uno de los cuestionarios o formularios accediendo a nuestras políticas de Privacidad y Cookies arriba indicadas o aquellas otras específicas que se le puedan mostrar en nuestro subdominios o micro-sites.</p>
        <p class="ordered-header">INFORMACIÓN LEGAL SOBRE RESPONSABILIDAD</p>
        <p>El Usuario accede a la web www.puntoapuntorenault.com bajo su única y entera responsabilidad. La web puede incluir formularios o páginas interactivas que requieran la inclusión de datos o informaciones por parte del Usuario para su traslado a RENAULT o a los concesionarios RENAULT a través de la propia web. En tales casos, el usuario deberá actuar de buena fe y de conformidad con la legislación vigente, y será el único y exclusivo responsable de la veracidad, adecuación y exactitud de todos los datos y manifestaciones que haga constar, así como de cualquier error cometido al cumplimentar tales datos. El mero envío por parte del Usuario de un formulario a través del site no supone necesariamente la aceptación del mismo por parte de RENAULT o de los concesionarios RENAULT , en tanto en cuanto no se haya contrastado la exactitud de la información y cualquier otro extremo requerido y no se haya comunicado la aceptación al Usuario.</p>
        <p>RENAULT realiza sus mayores esfuerzos con el fin de asegurar la disponibilidad técnica de la web y la adecuada transmisión de la información contenida en ella, siempre en la medida de sus posibilidades. No obstante, lo anterior, debido a la naturaleza de la red Internet no es posible garantizar de forma absoluta estos extremos. Consecuentemente, RENAULT, las empresas del grupo RENAULT y los concesionarios RENAULT no serán responsables por daños y perjuicios de cualquier naturaleza que eventualmente se pudieran derivar, entre otras causas similares, de los siguientes actos o sucesos: la interrupción de funcionamiento o falta de disponibilidad de su página web; la privacidad y la seguridad de la página web en cuanto a su utilización por parte del Usuario, y/o el acceso no consentido de terceros no autorizados; la eventual transmisión de software u otros elementos que afecten negativamente a los sistemas informáticos; cualquier otra pérdida o daño indirecto o de carácter consecuencial, como, principalmente, daños materiales, pérdidas de datos o de programa, perjuicios financieros, consecuencia del uso de dicho sitio web o de las webs a los que ésta se encuentra vinculada. El acceso a los productos y servicios presentados en la web www.puntoapuntorenault.com puede ser objeto de normativas especiales e incluso de restricciones y/o prohibiciones en algunos países. Por tanto, debe asegurarse de que el país en el que se conecta permite la consulta de la web www.puntoapuntorenault.com.</p>
        <p class="ordered-header">LINKS HIPERTEXTO</p>
        <p>La actualización de un link hipertexto hacia la web www.puntoapuntorenault.com necesita una autorización previa escrita por RENAULT. Si desea incluir en su web un link hipertexto hacia nuestra web, debe contactar con el responsable de la web www.puntoapuntorenault.com RENAULT no puede en ningún caso ser responsable de la actualización de las webs que hacen un link hipertexto desde www.puntoapuntorenault.com y no tiene ninguna responsabilidad sobre el contenido, los productos, los servicios, etc. disponibles en esas webs o a partir de esas webs.</p>
        <p class="ordered-header">DERECHO APLICABLE</p>
        <p>La presente información legal está sometida al derecho español. El idioma de la información legal es el español. En caso de litigio, los tribunales de la ciudad de Madrid (capital) serán los únicos competentes.</p>
        <p class="ordered-header">PUESTA AL DÍA DE LA INFORMACIÓN LEGAL</p>
        <p>RENAULT se reserva el derecho de modificar y actualizar en cualquier momento el acceso a la web, así como la información legal. Estas modificaciones y actualizaciones afectan al usuario en el sentido de que debe acudir a esta sección para verificar la información legal en vigor en cada conexión.</p>
      </div>
    </div>
  </div>
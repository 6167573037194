import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { PuntoapuntoCrmRequestLogDTO } from '../../../models/puntoapunto-crm-request-log-dto';
import { CRMRequestLogService } from '../../../services/crm-request-log.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FileService } from '../../../services/file.service';
import { saveAs } from 'file-saver';
import * as Highcharts from 'highcharts';
import funnel from 'highcharts/modules/funnel';
import { Subject } from 'rxjs';
import { visibleContentAnimation } from '../../../animations';

declare var $: any;
funnel(Highcharts);

@Component({
  selector: 'app-crm-info',
  templateUrl: './crm-info.component.html',
  animations: [visibleContentAnimation]
})

export class CrmInfoComponent implements OnInit {
  data: PuntoapuntoCrmRequestLogDTO = null;
  Html: SafeHtml;
  reactivity = 0;
  unsubscribePorc = 0;
  @Input() id = null;
  @Input() callDownloadExcel: Subject<boolean>;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: any = {
    legend: {
      enabled: false
    },
    chart: {
      type: 'funnel',
      backgroundColor: 'transparent',
    },
    title: {
      text: null
    },
    colors: ['#000000', '#5CBA63', '#FFBD69', '#4B9BF5', '#d8c4a0', '#CB4B32', '#60504a', '#66d8d0', '#244f35'],
    lang: {
      decimalPoint: ',',
      thousandsSep: '.'
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderColor: '#F1F1F2',
        borderWidth: 8,
        dataLabels: {
          crop: false,
          formatter: function() {            
              return '<small>' + this.point.key + ':<br> ' + Highcharts.numberFormat(this.y,0,',','.') + ' ' + this.point.i + '</small>';
          }
        }
      }
    },
    tooltip: {
        formatter: function () {
          return '<small><strong>' + this.point.key + ':</strong><br> ' + Highcharts.numberFormat(this.y,0,',','.') + ' ' + this.point.i +'</small>';
        }
    },
    series: [{
      name: '',
      data: []
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 420
        },
        chartOptions: {
          plotOptions: {
            funnel: {
              dataLabels: {
                distance: 0
              }
            }
          }
        }
      }]
    }
  };

  constructor(private service: CRMRequestLogService,
    private fileService: FileService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if (this.id != null) {
      this.service.get(this.id).subscribe(resp => {
        this.data = resp;
        this.reactivity = this.data.Conts.Click * 100 / this.data.Conts.Open;
        this.unsubscribePorc = this.data.Conts.Unsubscribe * 100 / this.data.Conts.Open;
        this.getHtml();

        let sendPercent = " (" + Math.round(this.data.Conts.Contacts > 0 ? this.data.Conts.Send * 100 / this.data.Conts.Contacts : 0) + "%)";
        let openPercent = " (" + Math.round(this.data.Conts.Send > 0 ? this.data.Conts.Open * 100 / this.data.Conts.Send : 0) + "%)";
        let clickPercent = " (" + Math.round(this.data.Conts.Open > 0 ? this.data.Conts.Click * 100 / this.data.Conts.Open : 0) + "%)";
        let errorPercent = " (" + Math.round(this.data.Conts.Contacts > 0 ? this.data.Conts.Error * 100 / this.data.Conts.Contacts : 0) + "%)";

        this.chartOptions.series[0].data = [
          
            { key: '<div class="funnel-chart-data-title">Contactos</div>', y: this.data.Conts.Contacts, i:'' },
            { key: '<div class="funnel-chart-data-title">Enviados</div>', y: this.data.Conts.Send, i: sendPercent },
            { key: '<div class="funnel-chart-data-title">Abiertos</div>', y: this.data.Conts.Open, i: openPercent },
            { key: '<div class="funnel-chart-data-title">Clics</div>', y: this.data.Conts.Click, i: clickPercent },     
            { key: '<div class="funnel-chart-data-title">Pendientes</div>', y: this.data.Conts.Pending, i: '' },     
            { key: '<div class="funnel-chart-data-title">Error</div>', y: this.data.Conts.Error, i: errorPercent }     
        ];
      }, (err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error al recuperar los datos',
          text: 'Ocurrió un error'
        });
      });
    }
    this.callDownloadExcel.subscribe(v => {
      if(v) {
        this.DownloadExcel();
        this.callDownloadExcel.next(false);
      }
    });
  }



  DownloadExcel() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor...'
    });
    Swal.showLoading();

    this.service.getFileCRMRequestDetailExcel(this.id).subscribe(blob => {
      Swal.close();
      saveAs(blob, "Puntoapunto_CRM_Info_Detail.xlsx");
    }), (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Error al acceder a la BD',
        text: 'Ocurrió un error'
      });
    }
  }

  getHtml() {
    this.fileService.getFileDataTextByUrl(this.data.HtmlFile.FileUri).subscribe(resp => {
      this.Html = this.sanitizer.bypassSecurityTrustHtml(resp);
    });
  }

  getStatusClass(status) {
    let cssClasses: string = 'd-inline-block ';

    if (status === 'ENVIADO') {
      cssClasses += 'sent';
    } else if (status === 'PENDIENTE' || status === 'EN PROCESO') {
      cssClasses += 'pending';
    } else if (status === 'CANCELADO' || status === 'ERROR') {
      cssClasses += 'canceled';
    }
    return cssClasses;
  }
}



import { Injectable } from '@angular/core';

@Injectable()
export class LanguageApp {
  public static spanish_datatables = {
    //processing: "Procesando...",
    search: "<span>Buscar:</span>",
    searchPlaceholder: "Buscar",
    lengthMenu: "<span>Mostrar</span> _MENU_ <span>elementos</span>",
    info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
    infoEmpty: "Mostrando ningún elemento.",
    infoFiltered: "(filtrado _MAX_ elementos total)",
    infoPostFix: "",
    loadingRecords: "Cargando registros...",
    zeroRecords: "No se encontraron registros",
    emptyTable: "No hay datos disponibles en la tabla",
    paginate: {
      first: "<<",
      previous: "<",
      next: ">",
      last: ">>"
    },
    aria: {
      sortAscending: ": Activar para ordenar la tabla en orden ascendente",
      sortDescending: ": Activar para ordenar la tabla en orden descendente"
    },
    buttons: {
      colvis: 'Seleccionar columnas'
    }
  }

  public static custom_dom_datatables =
  "<'custom-datatable'" +
  "<'custom-datatable-tools'<'row'<'col-auto custom-datatable-length'l><'col custom-datatable-search'f>>>" +
  "<'row'<'col-sm-12'tr>>" +
  "<'row'<'col'ip>>>";
}


export enum Status {
    NoActivo = 0,
    Activo = 1,
    Baja = 2,
    EnProceso = 3,
    Enviado = 4,
    Error = 5,
    Pendiente = 6,
    Cancelado = 7
}

export enum RatingType {
    Catalog = 1
}
import { Component, OnInit, DoCheck, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Helpers } from '../../../helpers/helpers';
import { AuthJwtService } from '../../../services/auth-jwt.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})

export class FooterComponent implements OnInit, DoCheck {

  UserAuthenticated = false;
  currentDate = new Date();
  currentYear = parseInt(this.datepipe.transform(this.currentDate, 'yyyy'));

  @Input() sublinks: {}[] = [];
  @Input() servicesTake: number;
  @Input() productsTake: number;
  oldSublinks: number;

  isInDevEnvironment = false;

  products: any;
  services: any;

  constructor(
    private router: Router,
    private authJwtService: AuthJwtService,
    private helper: Helpers,
    public datepipe: DatePipe
  ) {
    this.UserAuthenticated = this.authJwtService.isAuthenticated();
  }

  ngOnInit(): void {
    this.authJwtService.isAuthenticationChanged().subscribe(resp => {
      this.UserAuthenticated = resp;
    });
    this.isInDevEnvironment = this.helper.isInDevEnvironment();
  }

  ngDoCheck() {
    let changeDetected = false;
    if (this.sublinks.length !== this.oldSublinks) {
      changeDetected = true;
      this.oldSublinks = this.sublinks.length;
    }

    if (changeDetected) {
        this.sublinks.forEach(el => {
          if(el['idMenu'] == 'menuProductos') {
            this.products =  el['Items'];
          };
          if(el['idMenu'] == 'menuServicios') {
            this.services =  el['Items'];
          };
        });
    }
  }

  logout() {
    this.authJwtService.logout();
    this.router.navigate(['/home']);
  }

  toggleDevStyleClass(event) {
    if (this.isInDevEnvironment) {
      if (event.detail === 3) {
        let body = document.body;
        if (body.classList.contains('dev-environment')) {
          body.classList.remove('dev-environment');
        } else {
          body.classList.add('dev-environment');
        }
      }
    }
  }

}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
declare var $: any;
declare var bootstrap: any;

@Injectable()
export class Helpers {

  isApiUrl(req: any) {
    if (req.url.startsWith(environment.urlAPI)) {
      return true;
    }
    return false;
  }

  isSameOriginUrl(req: any) {
    // It's an absolute url with the same origin.
    if (req.url.startsWith(`${window.location.origin}/`)) {
      return true;
    }

    // It's a protocol relative url with the same origin.
    // For example: //www.example.com/api/Products
    if (req.url.startsWith(`//${window.location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    if (/^\/[^\/].*/.test(req.url)) {
      return true;
    }

    // It's an absolute or protocol relative url that
    // doesn't have the same origin.
    return false;
  }

  setDataTableSearchDelay() {
    var searchDelay = null;

    setTimeout(function () {
      $('.dataTables_filter input')
        .off('keyup.DT search.DT input.DT paste.DT cut.DT')
        .on('keyup.DT search.DT input.DT paste.DT cut.DT', function (e) {
          var search = $('div.dataTables_filter input').val();
          clearTimeout(searchDelay);

          searchDelay = setTimeout(function () {
            if (search != null) {
              $.fn.dataTable.tables({ api: true, visible: true }).search(search).draw();
            }
          }, 1000);
        });
    }, 1000);
  }
  /**
    * Activa los tooltips de Bootstrap
    * Usado tras un cambio en el DOM
    * Sobre todo en los datatables
    */
  enableBsTooltips() {
    // $('.tooltip').remove();
    // var tooltipElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    // tooltipElements.forEach(function (tooltipElement) {
    //   $('.tooltip').remove();
    //   var tooltip = bootstrap.Tooltip.getOrCreateInstance(tooltipElement);
    // });
    // $('[data-bs-toggle="tooltip"]').on("mousedown", function () {
    //   $('.tooltip').remove();
    // });
  }

  clearBsTooltips() {
    $('.tooltip').remove();
  }

 getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => {
      if (object[key] != null) {
        if (object[key].constructor.name == "Array" && (object[key].length > 0 && object[key][0].constructor.name != "File")) {
          let i = 0;
          object[key].forEach(function (arr) {
            formData.append(`${key}[${i}]`, arr);
            i++;
          })
        } else if (object[key].constructor.name == "Array" && (object[key].length > 0 && object[key][0].constructor.name == "File")) {
          object[key].forEach(function (arr) {
            formData.append(key, arr);
          });
        }
        else if (object[key].constructor.name != "Array") {
          formData.append(key, object[key]);
        }
      }
    });
    return formData;
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  getMonths() {
    let mesesDelAnio: { value: number; label: string }[] = [
      { value: 1, label: 'Enero' },
      { value: 2, label: 'Febrero' },
      { value: 3, label: 'Marzo' },
      { value: 4, label: 'Abril' },
      { value: 5, label: 'Mayo' },
      { value: 6, label: 'Junio' },
      { value: 7, label: 'Julio' },
      { value: 8, label: 'Agosto' },
      { value: 9, label: 'Septiembre' },
      { value: 10, label: 'Octubre' },
      { value: 11, label: 'Noviembre' },
      { value: 12, label: 'Diciembre' },
    ];
    return mesesDelAnio;
  }

  /**
   * Comprueba si estamos en un entorno de desarrollo
   * Usa como referencia la URL de la API
   */
  isInDevEnvironment() {


    // It's a development local url.
    if (environment.urlAPI.startsWith('https://localhost')) {
      return true;
    }

    return false;
  }

  animateDataTable(table: HTMLElement) {
    var tableWrapper = table.closest('div.fadein-table');
    tableWrapper.classList.add('show');
  }

  renderPhotoHeader() {
    let photoHeaders = document.getElementsByClassName('photo-header');

    for (let el of Array.from(photoHeaders)) {

      const header = el as HTMLElement;

      let height = header.getAttribute('data-header-height');
      let bgImage = header.getAttribute('data-header-img');

      header.style.height = height + 'px';
      header.style.backgroundImage = 'url("' + bgImage + '")';

      let overlay1 = document.createElement("div");
      overlay1.classList.add('overlay-1', 'hidden');
      overlay1.style.backgroundImage = 'url("' + bgImage + '")';

      let overlay2 = document.createElement("div");
      overlay2.classList.add('overlay-2', 'hidden');
      overlay2.style.backgroundImage = 'url("' + bgImage + '")';

      header.appendChild(overlay1);
      header.appendChild(overlay2);

      setTimeout(() => {
              overlay1.classList.remove('hidden');

              setTimeout(() => {
              overlay2.classList.remove('hidden');
            }, 300);
      }, 300);
    }
  }

  groupByAndCount<T>(arr: T[], prop: keyof T): GroupedResult<T> {
    return arr.reduce((acc: GroupedResult<T>, obj: T) => {
        const key = String(obj[prop]);
        acc[key] = (acc[key] || 0) + 1;
        return acc;
    }, {});
  }

}

export interface GroupedResult<T> {
  [key: string]: number;
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ChangeOrderDTO } from '../models/change-order-dto';
import { DataTablesResponse } from '../models/datatables';
import { PromotionFilter, PuntoapuntoPromotionDTO, PuntoapuntoPromotionFamilyDTO, PuntoapuntoPromotionLogDTO } from '../models/puntoapunto-promotion-dto';
import { Select2Data } from 'ng-select2-component';


@Injectable({
  providedIn: 'root'
})

export class PromotionService {

  private pathAPI = environment.urlAPI;
  private controller = "PuntoapuntoPromotion/";

  constructor(private http: HttpClient) { }


  public GetPuntoapuntoPromotionLst(): Observable<PuntoapuntoPromotionDTO[]> {
    return this.http.get<PuntoapuntoPromotionDTO[]>(this.pathAPI + this.controller + "GetPuntoapuntoPromotionLst/");
  }

  public GetPuntoapuntoAllPromotionLst(filter: PromotionFilter): Observable<PuntoapuntoPromotionDTO[]> {
    return this.http.post<PuntoapuntoPromotionDTO[]>(this.pathAPI + this.controller + "GetPuntoapuntoAllPromotionLst/", filter);
  }

  public GetPuntoapuntoAllPromotionLstCount(filter: PromotionFilter): Observable<number> {
    return this.http.post<number>(this.pathAPI + this.controller + "GetPuntoapuntoAllPromotionLstCount/", filter);
  }

  public get(id: string): Observable<PuntoapuntoPromotionDTO> {
    return this.http.get<PuntoapuntoPromotionDTO>(this.pathAPI + this.controller + id);
  }

  public post(data: FormData): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller, data);
  }

  public put(id: string, data: FormData): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + id, data);
  }

  public changeStatus(id: string, idStatus: number): Observable<any> {
    return this.http.put<any>(this.pathAPI + this.controller + 'ChangeStatus/' + id + '/' + idStatus, null);
  }

  public changeOrder(data: ChangeOrderDTO[]): any {
    return this.http.post<any>(this.pathAPI + this.controller + 'ChangeOrder', data);
  }

  public getDataTableOrder(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + this.controller + 'GetDataTableOrder/', data);
  }

  public getDataTable(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + this.controller + 'GetDataTable/', data);
  }

  public postLog(data: PuntoapuntoPromotionLogDTO): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller + "InsertLog", data);
  }

  public getPuntoapuntoPromotionType(): Observable<Select2Data> {
    return this.http.get<Select2Data>(this.pathAPI + this.controller + 'GetPuntoapuntoPromotionType');
  }

  public getHtml(id: string, idRol: number): Observable<string> {
    return this.http.get<string>(this.pathAPI + this.controller + 'GetHtml/' + id + "/" + idRol,{ responseType: 'text' as 'json' });
  }

  public getFamilies(id: string): Observable<PuntoapuntoPromotionFamilyDTO[]> {
    return this.http.get<PuntoapuntoPromotionFamilyDTO[]>(this.pathAPI + this.controller + 'GetFamilies/' + id);
  }

  public postFamilies(id:string, data: PuntoapuntoPromotionFamilyDTO[]): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller + 'PostFamilies/' + id, data);
  }

}

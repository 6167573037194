import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PuntoapuntoCrmRequestLogDTO } from '../models/puntoapunto-crm-request-log-dto';


@Injectable({
  providedIn: 'root'
})

export class CRMRequestLogService {

  private pathAPI = environment.urlAPI;
  private controller = "PuntoapuntoCRMRequestLog/";

  constructor(private http: HttpClient) { }

  public get(id: string): Observable<PuntoapuntoCrmRequestLogDTO> {
    const params = new HttpParams().set('id', id);
    return this.http.get<PuntoapuntoCrmRequestLogDTO>(this.pathAPI + this.controller, {params});
  }

  getFileCRMRequestDetailExcel(id: string): Observable<Blob> {
    let requestOptions: Object = {
      responseType: 'blob'
    }
    let endpoint = this.pathAPI + this.controller + 'GetFileCRMRequestDetailExcel/' + id;
    return this.http.post<Blob>(endpoint, null, requestOptions);
  }
}

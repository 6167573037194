@if(externalContainer) {
<ng-container *ngTemplateOutlet="innerFilters"></ng-container>
} @else {
<div class="filter-form">
  <ng-container *ngTemplateOutlet="innerFilters"></ng-container>
</div>
}

<ng-template #innerFilters>
  <div class="row align-items-end">
    <div class="col-sm-6 col-lg mb-3">
      <div class="commonfilter-rol-container form-group">
        <label class="form-label">Canal</label>
        <select2 [resettable]="true" [(ngModel)]="rolSelected" [multiple]="true" placeholder="Seleccionar Canal" (update)="selectRol()" [data]="rolData"></select2>
      </div>
    </div>

    <div class="col-sm-6 col-lg mb-3">
      <div [hidden]="hideDr" class="commonfilter-dr-container form-group">
        <label class="form-label">DR<span [hidden]="!requireDr">*</span></label>
        <select2 [resettable]="true" [(ngModel)]="drSelected" [multiple]="multiDr" placeholder="Seleccionar DR" (update)="selectDR()" [data]="drData" [required]="requireDr"></select2>
      </div>
    </div>

    <div class="col-sm-6 col-lg mb-3">
      <div [hidden]="hidePvGroup" class="commonfilter-pvgroup-container form-group">
        <label class="form-label">Grupo PV<span [hidden]="!requirePvGroup">*</span></label>
        <select2 [resettable]="true" [(ngModel)]="grupopvSelected" [multiple]="multiPvGroup" placeholder="Seleccionar grupo PV" (update)="selectGroupPV()" [data]="grouppvData" [required]="requirePvGroup"></select2>
      </div>
    </div>

    <div class="col-sm-6 col-lg mb-3">
      <div [hidden]="hideDistri" class="commonfilter-distri-container form-group">
        <label class="form-label">Nº de cuenta Punto de Red<span [hidden]="!requireDistri">*</span></label>
        <select2 [resettable]="true" [(ngModel)]="distributorSelected" [multiple]="multiDistri" placeholder="Seleccionar Punto de Red" (update)="selectDistri()" [data]="distributorData" [required]="requireDistri"></select2>
      </div>
    </div>

    <div class="col-sm-6 col-lg mb-3">
      <div [hidden]="hideTaller" class="commonfilter-distri-container form-group">
        <label class="form-label">Taller<span [hidden]="!requireTaller">*</span></label>
        <select2 [resettable]="true" [(ngModel)]="tallerSelected" [multiple]="multiTaller" placeholder="Seleccionar Taller" (update)="selectTaller()" [data]="tallerData" [required]="requireTaller"></select2>
      </div>
    </div>

    <div *ngIf="!selectOnChange || !hideResetFilter" class="col-auto mb-3">
      <button *ngIf="!selectOnChange" type="button" class="btn-icon btn-black btn-sq-sm" title="Filtrar" (click)="emitFilter()">
        <div class="svg-icon"><svg viewBox="0 0 150 150">
            <use xlink:href="assets/images/icons/icon-nav-search.svg#img" href="assets/images/icons/icon-nav-search.svg#img"></use>
          </svg>
        </div>
        <div class="visually-hidden">Filtrar</div>
      </button>
      <button *ngIf="!hideResetFilter && someSelected" type="button" class="btn-icon btn-black-border btn-sq-sm ms-2" title="Restaurar filtros" (click)="emitReset()">
        <div class="svg-icon"><svg viewBox="0 0 150 150">
            <use xlink:href="assets/images/icons/ico_cross.svg#img" href="assets/images/icons/ico_cross.svg#img"></use>
          </svg>
        </div>
        <div class="visually-hidden">Restaurar filtros</div>
      </button>
    </div>
  </div>
</ng-template>
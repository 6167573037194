<div class="points-balance">
  <div *ngIf="userPoints">
    <div class="points-balance-status">
      <div class="left-points">
        <small>Disponibles</small><br>
        {{userPoints.TotalPoints | number}} <small>puntos</small>
      </div>
      <div class="spent-points">
        <small>Consumidos</small><br>
        <span class="spent-points">{{totalPointsSpent | number}}&nbsp;</span> <small>puntos</small>
      </div>
      <div class="lost-points">
        <small>Caducados</small><br>
        {{totalPointsLost | number}} <small>puntos</small>
      </div>
      <div class="to-lost-points">
        <small>Caducan a final de este año</small><br>
        {{totalPointsToLostThisYear | number}} <small>puntos</small>
      </div>
    </div>
    <div class="table-responsive-lg">
      <table class="table data-tables w-100">
        <thead>
          <tr>
            <th class="text-center w-10">Año</th>
            <th>Conseguidos</th>
            <th>Disponibles</th>
            <th class="spent-col">Consumidos</th>
            <th>Caducados</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let pointYear of userPoints.PointYear">
            <tr data-bs-toggle="collapse" [attr.data-bs-target]="'#balance-byyears_'+ pointYear.Year" aria-expanded="false" [attr.aria-controls]="'balance-byyears_'+ pointYear.Year">
              <td class="text-center w-10"><span class="data">{{pointYear.Year}}</span></td>
              <td class="text-end"><span class="data earned-points">{{pointYear.PointsEarned | number}}</span> <small>Ptos.</small></td>
              <td class="text-end"><span class="data left-points">{{pointYear.PointsLeft | number}}</span> <small>Ptos.</small></td>
              <td class="text-end spent-col">
                <span class="data spent-points">{{pointYear.PointsSpent | number}}</span> <small>Ptos.</small>
                <button *ngIf="pointYear.PointsSpentYear.length > 0" type="button" class="btn-expand-intable collapsed" title="Ver más" data-bs-toggle="collapse" [attr.data-bs-target]="'#balance-byyears_'+ pointYear.Year" aria-expanded="false" [attr.aria-controls]="'balance-byyears_'+ pointYear.Year">
                  <div class="svg-icon plus"><svg viewBox="0 0 150 150">
                      <use xlink:href="assets/images/icons/icon-plus.svg#img" href="assets/images/icons/icon-plus.svg#img"></use>
                    </svg>
                  </div>
                  <div class="svg-icon minus"><svg viewBox="0 0 150 150">
                      <use xlink:href="assets/images/icons/icon-minus.svg#img" href="assets/images/icons/icon-minus.svg#img"></use>
                    </svg>
                  </div>
                  <div class="visually-hidden">Ver más</div>
                </button>
                <div *ngIf="pointYear.PointsSpentYear.length > 0" class="collapse breakdown" [id]="'balance-byyears_'+ pointYear.Year">
                  <table class="breakdown">
                    <tr *ngFor="let pointDetail of pointYear.PointsSpentYear">
                      <td><small>obtenidos en </small> <span class="data">{{pointDetail.Year}}</span></td>
                      <td><span class="data spent-points">{{pointDetail.Points | number}}</span> <small>Ptos.</small></td>
                    </tr>
                  </table>
                </div>
              </td>
              <td class="text-end"><span class="data lost-points">{{pointYear.PointsLost | number}}</span> <small>Ptos.</small></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { DatePipe, ViewportScroller } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { AuthJwtService } from '../../services/auth-jwt.service';
import { heightContentAnimation, visibleContentAnimation } from '../../animations';

@Component({
  templateUrl: './points.component.html',
  animations: [visibleContentAnimation, heightContentAnimation]
})

export class PointsComponent implements OnInit {

  UserAuthenticated = false;

  prefooterModulesSet = 'home';
  breadcrumbs = [
    {
      text: 'Inicio',
      link: '/home'
    },
    {
      text: 'Cómo conseguir puntos',
      link: '/como-conseguir-puntos'
    }
  ];
  currentDate = new Date();
  currentYear = parseInt(this.datepipe.transform(this.currentDate, 'yyyy'));
  faqs: any[] = [];
  scrollTo = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private scroller: ViewportScroller,
    public datepipe: DatePipe,
    private sharedService: SharedService,
    private authJwtService: AuthJwtService
  ) {
    this.UserAuthenticated = this.authJwtService.isAuthenticated();
  }

  ngOnInit(): void {
    this.scrollTo = this.route.snapshot.paramMap.get('faqs');
    this.getFaqs();
  }

  getFaqs() {
    this.sharedService.getFaqs().subscribe(res => {
      this.faqs = res.items;
      setTimeout(() => {
        this.scrollToAnchor();
      }, 500);
    });
  }

  scrollToAnchor() {
    if (this.scrollTo != '') {
      this.scroller.scrollToAnchor(this.scrollTo);
    }
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PuntoapuntoUserDTO, PuntoapuntoUserPostDTO } from '../models/puntoapunto-user-dto';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map } from 'rxjs';
import { DataTablesResponse } from '../models/datatables';
import { PuntoapuntoEmailPoolDTO } from '../models/puntoapunto-email-pool-dto';
import { PuntoapuntoDistributorDataModel } from '../models/distributor-model';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private pathAPI = environment.urlAPI;
  private controller = "PuntoapuntoUser/";

  public userData = new BehaviorSubject<PuntoapuntoUserDTO>(null);
  public userDistributorData = new BehaviorSubject<PuntoapuntoDistributorDataModel[]>([]);  

  constructor(private http: HttpClient) { }

  public initializeUserData() {
    const that = this;
    this.getUserInfo().subscribe(resp => {
      that.setUserData(resp);
    });
    this.getUserDistributorsBBDD().subscribe(resp => {
      that.setUserDistributor(resp);
    });
  }

  public getUserInfo(): Observable<PuntoapuntoUserDTO> {
    return this.http.get<PuntoapuntoUserDTO>(this.pathAPI + this.controller + 'GetLoggedUserInfo');
  }

  public getUserDistributorsBBDD(): Observable<PuntoapuntoDistributorDataModel[]> {
    return this.http.get<PuntoapuntoDistributorDataModel[]>(this.pathAPI + this.controller + 'GetLoggedUserDistributors');
  }

  setUserData(user: PuntoapuntoUserDTO) {
    this.userData.next(user);
  }

  setUserDistributor(data: PuntoapuntoDistributorDataModel[]) {
    this.userDistributorData.next(data);
  }

  getUserData(): Observable<PuntoapuntoUserDTO> {


    if (this.userData.value != null) {
      return this.userData.asObservable();
    }
    else {
      return this.getUserInfo().pipe(map((userData) => {
        this.userData.next(userData);
        return userData;
      }),
        catchError((err) => {
          return this.userData.asObservable();
        }));
    }
  }

  getUserDistributors(): Observable<PuntoapuntoDistributorDataModel[]> {
    return this.userDistributorData.asObservable();
  }

  public get(id: string): Observable<PuntoapuntoUserDTO> {
    return this.http.get<PuntoapuntoUserDTO>(this.pathAPI + this.controller + id);
  }

  public insertAdmin(data: PuntoapuntoUserPostDTO): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller + "InsertAdmin", data);
  }

  public getDataTable(data: any): Observable<DataTablesResponse> {
    return this.http.post<DataTablesResponse>(this.pathAPI + this.controller + 'GetDataTable', data);
  }

  public confirmUserDelete(idUser: string, confirm: boolean): Observable<PuntoapuntoUserDTO> {
    return this.http.get<PuntoapuntoUserDTO>(this.pathAPI + this.controller + "ConfirmUserDelete/" + idUser + "/" + confirm);
  }

  public getLstEmailBaja(idUser: string): Observable<PuntoapuntoEmailPoolDTO[]> {
    return this.http.get<PuntoapuntoEmailPoolDTO[]>(this.pathAPI + this.controller + "GetLstEmailBaja/" + idUser);
  }

  public getLstEmailAlta(idUser: string): Observable<PuntoapuntoEmailPoolDTO[]> {
    return this.http.get<PuntoapuntoEmailPoolDTO[]>(this.pathAPI + this.controller + "GetLstEmailAlta/" + idUser);
  }

  //#region CONDICIONES

  public CheckConditionsDistributor(): Observable<boolean> {
    return this.http.get<boolean>(this.pathAPI + this.controller + 'CheckConditionsDistributor');
  }

  public SetConditionsDistributor(acceptCrm: boolean): Observable<PuntoapuntoUserDTO> {
    return this.http.get<PuntoapuntoUserDTO>(this.pathAPI + this.controller + 'SetConditionsDistributor/' + acceptCrm);
  }

  //#endregion
}
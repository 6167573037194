<div class="cont__edit-form-simple">
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <section class="admin__form">
        <div class="form-group">
          <label class="form-label">Buscador</label>
          <input type="text" class="form-control" [(ngModel)]="familyFind" (ngModelChange)="searchFamily()">
        </div>
      </section>
    </div>
  </div>
</div>
<div class="cont_families">
  <div class="row">
    <div class="col-md-3 mb-3">
      <h4>Selector de familias</h4>
      <div ngbAccordion>
        <div ngbAccordionItem *ngFor="let segment of model">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Segmento {{segment.Segment}}</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <table class="table data-tables dense__table">
                  <thead>
                    <tr>
                      <th class="text-center w-6">Familia</th>
                      <th>Denominación</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let family of segment.Familiy">
                      <td class="text-center">{{family.Family}}</td>
                      <td>{{family.Description}}</td>
                      <td class="text-center w-4">
                        <div class="cont__btn-option"><button type="button" class="btn-option btn-option-add" (click)="addFamily(family, segment.Segment)" title="Añadir familia"><span class="visually-hidden">Añadir familia</span></button></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-9 mb-3">
      <h4>Familias seleccionadas</h4>
      <div ngbAccordion>
        <div ngbAccordionItem *ngFor="let segment of selectedFamilies" [collapsed]="false">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Segmento {{segment.Segment}}</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="table-responsive-lg">
                  <table class="table data-tables dense__table">
                    <thead>
                      <tr>
                        <th class="text-center w-6">Familia</th>
                        <th>Denominación</th>
                        <th class="text-center w-14">Reference</th>
                        <th *ngIf="ShowPoints" class="text-center w-8">Euros</th>
                        <th *ngIf="ShowPoints" class="text-center w-6">Puntos</th>
                        <th class="text-center w-24">Roles</th>
                        <th class="text-center w-4"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let family of segment.Families">
                        <td class="text-center">{{family.Family}}</td>
                        <td>{{family.Description}}</td>
                        <td>
                          <input type="text" class="form-control" [(ngModel)]="family.Reference" (ngModelChange)="emitSelection()">
                        </td>
                        <td *ngIf="ShowPoints">
                          <input type="number" class="form-control" [(ngModel)]="family.Euros" (ngModelChange)="emitSelection()">
                        </td>
                        <td *ngIf="ShowPoints">
                          <input type="number" class="form-control" [(ngModel)]="family.Points" (ngModelChange)="emitSelection()">
                        </td>
                        <td class="text-center">
                          <div class="cont__switch-group">
                            <div class="form-check form-switch" *ngFor="let rol of rolData">
                              <input class="form-check-input" type="checkbox" value="{{rol.value}}" name="{{rol.label}}" (change)="updateRolData($event, family)" [checked]="(family.Roles && (-1 !== family.Roles.indexOf(rol.value)) ? 'checked' : '')">
                              <label class="form-check-label">{{rol.label}}</label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="cont__btn-option"><button type="button" class="btn-option btn-option-delete" (click)="removeFamily(family)" title="Eliminar"><span class="visually-hidden">Eliminar</span></button></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, TemplateRef, ViewChild, OnInit, ViewChildren, QueryList, ElementRef, Input, DoCheck, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, Router, IsActiveMatchOptions } from '@angular/router';
import { AuthJwtService } from '../../../services/auth-jwt.service';
import { UserService } from '../../../services/user.service';
import { PuntoapuntoUserDTO } from '../../../models/puntoapunto-user-dto';
import { heightContentAnimation, visibleContentAnimation } from '../../../animations';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { SharedService } from '../../../services/shared.service';
import { Item, MenuDTO } from '../../../models/menu-dto';
import { TrackingService } from '../../../services/tracking.service';
import { LogType } from '../../../enums/logtype';
import { UserAnimationPointDTO } from '../../../models/user-animation-point-dto';
import { UserAnimationPointService } from '../../../services/user-animation-point.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  animations: [visibleContentAnimation, heightContentAnimation],
})
export class HeaderComponent implements OnInit, DoCheck {

  //menu: MenuDTO;
  menu: Item[];
  menuMiArea: Item[];
  userInfo: PuntoapuntoUserDTO;
  userPoints: UserAnimationPointDTO;

  UserAuthenticated = false;
  isAdmin = false;
  isAdminRenault = false;
  overDropdown = false;

  @Input() sublinks: {}[] = [];
  @Input() servicesTake: number;
  @Input() productsTake: number;
  @Output() onLogOut = new EventEmitter<boolean>();
  oldSublinks: number;

  @ViewChild('notifications', { read: TemplateRef }) notifications: TemplateRef<any>;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;

  constructor(private router: Router,
    private authJwtService: AuthJwtService,
    private trackingService: TrackingService,
    private userService: UserService,
    private userPointService: UserAnimationPointService,
    private sharedService: SharedService,) {

    this.UserAuthenticated = this.authJwtService.isAuthenticated();

    if (this.UserAuthenticated)
      this.userService.initializeUserData();

    this.getUserInfo();

    setTimeout(() => {
      this.router.events.subscribe((ev) => {
        if (this.UserAuthenticated) {
          if (ev instanceof NavigationEnd) {
            this.trackUser(LogType.Nav);
          }
        }
      });
    }, 1500);
  }

  ngOnInit() {
    this.authJwtService.isAuthenticationChanged().subscribe(resp => {
      this.UserAuthenticated = resp;
      this.getUserInfo();
    });
    this.getMenu();
    this.toogleBodyPosClass('navbarMenuContainer');
    this.getLoginStatus();
  }

  ngDoCheck() {
    let changeDetected = false;
    if (this.sublinks.length !== this.oldSublinks) {
      changeDetected = true;
      this.oldSublinks = this.sublinks.length;
    }

    if (changeDetected) {
      this.addLinkstoMainMenu();
    }
  }

  getMenu() {
    this.sharedService.getMenu().subscribe(resp => {
      this.menu = resp.Items.filter(o => o.Id != "menuMiArea");
      this.menuMiArea = resp.Items.filter(o => o.Id == "menuMiArea");
    });
    this.onLogOut.emit(true);
    this.addLinkstoMainMenu();
  }

  getUserInfo() {
    this.userService.getUserData().subscribe(res => {
      this.userInfo = res;
      if (this.userInfo) {
        this.isAdmin = this.userInfo.RolesListString.includes("Administrador");
        this.isAdminRenault = this.userInfo.RolesListString.includes("Administrador") || this.userInfo.RolesListString.includes("AdminRecsa");
        if (this.authJwtService.isAuthenticated()) {
          this.userPointService.ReloadUserAnimationPoints();
          this.getUserPoints();
        }
      }
    });
  }

  getUserPoints() {
    this.userPointService.getUserAnimationPoints().subscribe(resp => {
      this.userPoints = resp;
    });
  }

  trackUser(idLogType: number) {
    this.trackingService.trackUser({ IdLogType: idLogType, Url: this.router.url }).subscribe();
  }

  getLoginStatus() {
    this.authJwtService.isAuthenticationChanged().subscribe(status => {
      this.getMenu();
    });
  }

  logout() {
    this.authJwtService.logout();
    this.router.navigate(['/login']);
  }

  addLinkstoMainMenu() {
    if (this.menu && this.menu.length > 0 && this.sublinks.length > 0) {
      this.sublinks.forEach(el => {
        let index = this.menu.map(function (e) { return e.Id; }).indexOf(el['idMenu']);

        const viewMoreProducts = {
          "Id": "",
          "Title": "Ver más productos",
          "Url": "products/",
          "Class": "subLink",
          "Roles": [],
          "SubItems": []
        }

        const viewMoreServices = {
          "Id": "",
          "Title": "Ver más servicios",
          "Url": "services/",
          "Class": "subLink",
          "Roles": [],
          "SubItems": []
        }

        if (el['idMenu'] == 'menuProductos' && el['Items'].length == this.productsTake && !el['Items'].includes(viewMoreProducts)) {
          el['Items'].push(viewMoreProducts);
        };

        if (el['idMenu'] == 'menuServicios' && el['Items'].length == this.servicesTake && !el['Items'].includes(viewMoreServices)) {
          el['Items'].push(viewMoreServices);
        };

        this.menu[index]['SubItems'] = el['Items'];
      });
    }
  }

  closeAllMenuDropdowns() {
    this.dropdowns.toArray().forEach(el => {
      el.close();
    });
  }

  toogleBodyPosClass(id) {
    const body = document.body;
    const myCollapsible = document.getElementById(id);
    const mainHeader = document.getElementsByClassName('main-header')[0];
    const mainHeaderToggler = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
    const that = this;

    myCollapsible.addEventListener('hidden.bs.collapse', event => {
      mainHeader.classList.add('sticky-top');
      mainHeader.classList.remove('fixed-top');
      body.style.paddingTop = '0';
      body.classList.remove('menu-open');
    });
    myCollapsible.addEventListener('show.bs.collapse', event => {
      mainHeader.classList.add('fixed-top');
      mainHeader.classList.remove('sticky-top');
      body.style.paddingTop = mainHeader.clientHeight + 'px';
      body.classList.add('menu-open');
    });

    window.addEventListener("resize", function () {
      mainHeader.classList.add('sticky-top');
      mainHeader.classList.remove('fixed-top');
      body.style.paddingTop = '0';
      body.classList.remove('menu-open');
      that.closeAllMenuDropdowns();
      if (!mainHeaderToggler.classList.contains('collapsed')) {
        mainHeaderToggler.click();
      }
    });

    this.router.events.subscribe((ev) => {
      mainHeader.classList.add('sticky-top');
      mainHeader.classList.remove('fixed-top');
      body.style.paddingTop = '0';
      body.classList.remove('menu-open');
      that.closeAllMenuDropdowns();
      if (!mainHeaderToggler.classList.contains('collapsed')) {
        mainHeaderToggler.click();
      }
    });
  }
  isRouteActive(urlPrefix: string): boolean {
    const currentUrl = this.router.url;
    return currentUrl.startsWith(urlPrefix);
  }
}

import { Sort } from "./change-order-dto";
import { DistributorParams } from "./distributor-params";
import { PuntoapuntoFileDTO } from "./puntoapunto-file-dto";

export class PuntoapuntoCatalogItemDTO {
    IdCatalogItem: string | null;
    IdCatalogItemInt: number;
    Status: boolean;
    CatalogItemCategory: string;
    Provider: string;
    IdCatalogItemCategory: number;
    PublishDate: string | null;
    UnpublishDate: string | null;
    Reference: string;
    Title: string;
    Description: string;
    Points: number;
    IsNew: boolean;
    UseHtml: boolean;
    Stock: number | null;
    PositiveFilters: string[];
    NegativeFilters: string[];
    CatalogItemProviders: number[];
    AverageRating: number;
    ImageFiles: PuntoapuntoFileDTO[];
    HtmlFile: PuntoapuntoFileDTO;
}

export class PuntoapuntoCatalogItemPostDTO {
    Status: boolean;
    IdCatalogItemCategory: number;
    PublishDate: string | null;
    UnpublishDate: string | null;
    Reference: string;
    Title: string;
    Description: string;
    Points: number;
    IsNew: boolean;
    UseHtml: boolean;
    Stock: number | null;
    HtmlFile: File | null;
    ImageFiles: File[] | null;
    PositiveFilters: string[] | null;
    NegativeFilters: string[] | null;
    CatalogItemProviders: number[];
}



export class PuntoapuntoCatalogItemCategoryDTO {
    IdCatalogItemCategory: string;
    Category: string;
}

export class PuntoapuntoCatalogProviderDTO {
    IdProvider: string | null;
    Provider: string;
    Email: string;
}

export class PuntoapuntoCatalogItemIdDTO {
    IdCatalogItem: string;
}

export class CatalogFilter {
    Search: string | null;
    Category?: number;
    InitPoint: number = 0;
    EndPoint?: number;
    Take: number = 12;
    Skip: number = 0;
    OrderBy: Sort = {
        Property: 'CreationDate',
        Descending: true
      };
    IdNotShow: number | null;
}

export class PuntoapuntoCatalogItemRatingDTO {
    IdItemRating: string;
    IdUser: string;
    IdCatalogItem: string;
    Rating: number;
    Comment: string;
}

export class PuntoapuntoCatalogItemOrderDTO {
    IdCatalogItem: string;
    Units: number = 1;
}

export class CatalogOrderDatatableFilter {
    Year: number | null;
    IdUser: string | null;
    IdStatus: number | null;
    IdCatalogItem: number | null;    
    DistributorParams?: DistributorParams;
}

export class PuntoapuntoCatalogItemOrderLastOrderDTO {
    OrderNumber: string;
    IdCatalogItemOrder: string;
    IdStatus: number;
    Status: string;
    Points: number;
    Title: string;
    Category: string;
    ImageFile: PuntoapuntoFileDTO | null;
}

export class PuntoapuntoCatalogItemLogDTO {
    IdCatalogItem: string;
}

<app-header (onLogOut)="getSublinks()" [sublinks]="sublinks" [servicesTake]="serviceFilter.Take" [productsTake]="serviceFilter.Take" />
<app-breadcrumbs [breadcrumbs]="breadcrumbs" [backLink]="backLink" />
<section class="main-section" [@routeAnimation]="getAnimationData()">
  <router-outlet #outlet="outlet" (activate)="getPrefooterModulesSet($event); getBreadcrumbs($event); helper.renderPhotoHeader()" />
</section>

@defer (on viewport) {
<app-prefooter [modulesSet]="prefooterModulesSet" />
} @placeholder {
<div>
</div>
}

<button type="button" id="back-to-top" class="btn-back-to-top" (click)="scrollToTop(backToTopTooltip)" #backToTopTooltip="ngbTooltip" ngbTooltip="Volver al inicio"><span class="visually-hidden">Volver al inicio</span></button>

<app-footer [sublinks]="sublinks" [servicesTake]="serviceFilter.Take" [productsTake]="serviceFilter.Take" />
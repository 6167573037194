<div *ngIf="userPoints" ngbAccordion [closeOthers]="true" class="accordion accordion-flush accordion-points">
  <div *ngFor="let sarYear of userPoints.SarYear" [ngClass]="{'disabled': sarYear.Animations.length < 1 }" ngbAccordionItem [disabled]="sarYear.Animations.length < 1">
    <h2 ngbAccordionHeader class="accordion-header">
      <button ngbAccordionButton>{{sarYear.Year}} <span class="accordion-points-detail"><small>Detalle puntos conseguidos:</small> {{sarYear.TotalPoints | number}} <small>Ptos.</small></span></button>
    </h2>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <ng-container *ngFor="let sarAnimation of sarYear.Animations; index as i">
            <div class="table-responsive-lg">
              <table class="table data-tables hover w-100">
                <thead>
                  <ng-container *ngIf="i==0">
                    <ng-container *ngFor="let initPoints of sarYear.InitialPoints">
                      <tr>
                        <th colspan="8" class="bg-light text-dark">
                          {{initPoints.Type}}
                          <button *ngIf="isRecsa" type="button" class="btn-icon btn-sq-sm ms-3" title="Descargar" (click)="downloadInitialPoints(initPoints.IdType, initPoints.Year)">
                            <div class="svg-icon"><svg viewBox="0 0 150 150">
                                <use xlink:href="assets/images/icons/icon-download.svg#img" href="assets/images/icons/icon-download.svg#img"></use>
                              </svg>
                            </div>
                            <div class="visually-hidden">Descargar</div>
                          </button>
                        </th>
                      </tr>
                      <tr>
                        <td colspan="8" class="text-end">
                          <span class="data earned-points">{{initPoints.InitialPoints | number}}</span> <small>Ptos.</small>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  
                  <tr class="animation-header-row">
                    <th colspan="8">
                      Animación del <span>{{sarAnimation.AnimationStartDate | date:'dd/MM/yyyy'}}</span> al <span>{{sarAnimation.AnimationEndDate | date:'dd/MM/yyyy'}}</span>

                      <button *ngIf="canViewDownload" type="button" class="btn-icon btn-light-border btn-sq-sm ms-3" title="Descargar" (click)="downloadAnimation(sarAnimation.AnimationStartDate, sarAnimation.AnimationEndDate)">
                        <div class="svg-icon"><svg viewBox="0 0 150 150">
                            <use xlink:href="assets/images/icons/icon-download.svg#img" href="assets/images/icons/icon-download.svg#img"></use>
                          </svg>
                        </div>
                        <div class="visually-hidden">Descargar</div>
                      </button>
                  </tr>
                  <tr [id]="'tr-subheader-row-animation-' + i" class="subheader-row collapsed">
                    <th class="w-10"><span class="hide-collapse">Segmento</span></th>
                    <th class="w-10"><span class="hide-collapse">Familia</span></th>
                    <th colspan="2" class="text-start"><span class="hide-collapse">Denominación</span></th>
                    <th class="text-end">Total Uds.</th>
                    <th class="text-end">Total Pvp</th>
                    <th class="text-end w-15"><span class="hide-collapse">Animación</span></th>
                    <th class="text-end w-20">Puntos conseguidos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr [id]="'tr-data-trigger-animation-' + i" class="animation-total-tr collapsible-tr collapsed" (click)="toggleAnimationDetail('animation-' + i, $event)">
                    <td colspan="4"><span class="data">TOTAL</span></td>
                    <td class="text-end"><span class="data">{{sarAnimation.TotalUnits | number}}</span></td>
                    <td class="text-end">
                      <span class="data">{{sarAnimation.TotalPvp | currency:'EUR'}}</span>
                      <button type="button" [id]="'data-trigger-animation-' + i" class="btn-expand-intable collapsed" title="Ver más">
                        <div class="svg-icon plus"><svg viewBox="0 0 150 150">
                            <use xlink:href="assets/images/icons/icon-plus.svg#img" href="assets/images/icons/icon-plus.svg#img"></use>
                          </svg>
                        </div>
                        <div class="svg-icon minus"><svg viewBox="0 0 150 150">
                            <use xlink:href="assets/images/icons/icon-minus.svg#img" href="assets/images/icons/icon-minus.svg#img"></use>
                          </svg>
                        </div>
                        <div class="visually-hidden">Ver más</div>
                      </button>
                    </td>
                    <td colspan="2" class="text-end">
                      <span class="data earned-points">{{sarAnimation.TotalPoints | number}}</span> <small>Ptos.</small>
                    </td>
                  </tr>
                  <ng-container *ngFor="let sarFamily of sortArrayBy(sarAnimation.Families, 'Segment')">
                    <tr [attr.data-detail-id]="'animation-' + i" class="collapsible-tr subrow closed" (click)="toggleMonthDetail('balance-bymonths_' + sarFamily.IdAnimation + sarFamily.Segment + sarFamily.Family, $event)">
                      <td>
                        <div><span class="data">{{sarFamily.Segment}}</span></div>
                      </td>
                      <td>
                        <div>{{sarFamily.Family}}</div>
                      </td>
                      <td colspan="2">
                        <div>{{sarFamily.Description}}</div>
                      </td>
                      <td class="text-end">
                        <div><span class="data">{{sarFamily.TotalUnits | number}}</span></div>
                      </td>
                      <td class="text-end">
                        <div>
                          <span class="data">{{sarFamily.TotalPvp | currency:'EUR'}}</span>
                          <button *ngIf="sarFamily.FamilyDetail.length > 0" type="button" [id]="'data-trigger-balance-bymonths_' + sarFamily.IdAnimation + sarFamily.Segment + sarFamily.Family" class="btn-expand-intable collapsed" title="Ver más">
                            <div class="svg-icon plus"><svg viewBox="0 0 150 150">
                                <use xlink:href="assets/images/icons/icon-plus.svg#img" href="assets/images/icons/icon-plus.svg#img"></use>
                              </svg>
                            </div>
                            <div class="svg-icon minus"><svg viewBox="0 0 150 150">
                                <use xlink:href="assets/images/icons/icon-minus.svg#img" href="assets/images/icons/icon-minus.svg#img"></use>
                              </svg>
                            </div>
                            <div class="visually-hidden">Ver más</div>
                          </button>
                        </div>
                      </td>
                      <td class="text-end">
                        <div><span class="data">{{sarFamily.Points}}x{{sarFamily.Euros}}</span></div>
                      </td>
                      <td class="text-end">
                        <div><span class="data earned-points">{{sarFamily.TotalPoints | number}}</span> <small>Ptos.</small></div>
                      </td>
                    </tr>
                    <tr [attr.data-detail-id]="'balance-bymonths_' + sarFamily.IdAnimation + sarFamily.Segment + sarFamily.Family" [attr.data-detail-parent-id]="'animation-' + i" class="subrow header-row closed">
                      <th class="void-cell"></th>
                      <th class="void-cell"></th>
                      <th class="void-cell"></th>
                      <th class="w-10 text-end">
                        <div>Mes</div>
                      </th>
                      <th class="text-end">
                        <div>Unidades</div>
                      </th>
                      <th class="text-end">
                        <div>Pvp</div>
                      </th>
                      <th class="void-cell"></th>
                      <th class="void-cell"></th>
                    </tr>
                    <tr [attr.data-detail-id]="'balance-bymonths_' + sarFamily.IdAnimation + sarFamily.Segment + sarFamily.Family" [attr.data-detail-parent-id]="'animation-' + i" *ngFor="let sarDetail of sortArrayBy(sarFamily.FamilyDetail, 'Month')" class="subrow data-row closed">
                      <td class="void-cell"></td>
                      <td class="void-cell"></td>
                      <td class="void-cell"></td>
                      <td class="text-end">
                        <div><strong>{{sarDetail.MonthString}}</strong></div>
                      </td>
                      <td class="text-end">
                        <div><span class="data">{{sarDetail.Units | number}}</span></div>
                      </td>
                      <td class="text-end">
                        <div><span class="data">{{sarDetail.Pvp | currency:'EUR'}}</span></div>
                      </td>
                      <td class="void-cell"></td>
                      <td class="void-cell"></td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div [@loadedHeightAnimation]="'visible'" *ngIf="breadcrumbs && breadcrumbs.length" class="container pt-3">
  <div class="breadcrumb-container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li *ngFor="let elem of breadcrumbs; index as i" class="breadcrumb-item"><a [ngClass]="{'active': i + 1 == breadcrumbs.length}" [routerLink]="[elem.link]" title="{{ elem.text }}">{{ elem.text }}</a></li>
      </ol>
    </nav>
    <a *ngIf="backLink" [routerLink]="[backLink[1]]" class="back-link">
      <i class="fa fa-angle-left" aria-hidden="true"></i>
      {{backLink[0]}}
    </a>
  </div>
</div>
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {  Observable } from 'rxjs';
import { LogDTO } from '../models/puntoapunto-log-dto';

@Injectable({
  providedIn: 'root'
})

export class TrackingService {

  private pathAPI = environment.urlAPI;
  private controller = "Tracking/";

  constructor(private http: HttpClient) { }


  public trackUser(data: LogDTO): Observable<any> {
    return this.http.post<any>(this.pathAPI + this.controller + "TrackUser", data);
  }

  public trackUnsubscribe(idRequestData: string): Observable<any> {
    return this.http.get<any>(this.pathAPI + this.controller + 'TrackCRM/' + idRequestData + '/' + 5);
  }

  public ViewCRMHtml(idRequestData: string): Observable<any> {
    return this.http.get<any>(this.pathAPI + this.controller + 'ViewCRMHtml/' + idRequestData);
  }
}
import { Component, Input } from '@angular/core';
import { visibleContentAnimation, heightContentAnimation } from '../../../animations';
import { PuntoapuntoDistributorDataModel } from '../../../models/distributor-model';

@Component({
  selector: 'info-distributor',
  templateUrl: './info-distributor.component.html',
  animations: [visibleContentAnimation, heightContentAnimation]
})
export class InfoDistributorComponent {

  @Input() 
  distributor: PuntoapuntoDistributorDataModel;

  constructor() { }

}


